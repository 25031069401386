.tooltip {
  position: absolute;
  color: #42403c;
  background-color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 180% */
  opacity: 1;
  display: inline-block;
  z-index: 15000 !important;
  padding: 8px 12px;
  border-radius: 8px;

  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
}

.tooltip.right {
  left: 110%;
}

.tooltip.left {
  right: 110%;
}

.tooltip.top {
  margin-top: -5px;
  transform: translate(-50%, -50%);
  left: 5px;
  top: -12px;
  z-index: 15000 !important;
}

.tooltip.top-avatar {
  margin-top: -9px;
  transform: translate(-50%, -50%);
  left: 15px;
  top: -9px;
  z-index: 15000 !important;
  background-color: #fff !important;
}

.tooltip.top-milestone {
  position: absolute;
  top: -30px;
  left: 42px;

  transform: translate(-50%, -50%) rotate(45deg);
}

.tooltip.bottom {
  margin-top: 5px;
  left: 5px;

  transform: translateY(170%);
}

.tooltip.bottom-left {
  margin-top: -40px;

  right: 0%;
  transform: translate(0%, 180%);
}

.tooltip.bottom-center {
  margin-top: -40px;

  left: 50%;
  transform: translate(-50%, 180%);
}

.tooltip .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: auto;
  margin-left: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #fff;
}

.tooltip.top .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

.tooltip.top-milestone .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

.tooltip.top-avatar .tooltip-arrow {
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #fff;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #fff;
  right: -5px;
  left: auto;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 20%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

.tooltip.bottom-center .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #fff;
}

.tooltip .tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

.tooltip .tooltip-label {
  max-width: 200px;

  color: #42403c;
  text-align: center;
  opacity: 1;
  border-radius: 4px;
  white-space: nowrap;
  max-width: none;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
}
