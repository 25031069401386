.hub-new-avatar-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  text-align: center;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 36.4px */
  border: 2px solid #fff;
  color: #fff;
  background-color: #80cd9b;
}

.hub-new-avatar-container.sm {
  width: 28px;
  height: 28px;
  font-size: 11px;
}

.hub-new-avatar-container.md {
  width: 44px;
  height: 44px;
  font-size: 14px;
}

.hub-new-avatar-container.lg {
  width: 68px;
  height: 68px;
  font-size: 28px;
}

.hub-new-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
}
