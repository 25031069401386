.hub-color-picker-wrapper{
    display: flex;
    flex-direction: row;
    width: 230px;
    flex-wrap: wrap;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 4px;
}

.hub-color-picker-color-active{
    border:1px solid red;    
    height:28px;
    border-radius:50%;
    -moz-border-radius:50%;
    -webkit-border-radius:50%;
    width:28px;
}


.hub-color-picker-color{
    height: 20px;
    width: 20px;
   /* box-shadow: 0px 0px 0px 2px white,0px 0px 0px 4px red;*/
    border-radius: 50%;
    background: #B15027;
    margin-right: 11px;
    cursor: pointer;
    
}