/* !! toDo Relative yeni eklendi. Bozarsa kaldır.  */
.hub-dataTable-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  position: relative;
}

.hub-dataTable {
  flex: 1;
  overflow: auto;
  border: 1px solid var(--hub100);
}

.hub-dataTable table {
  margin-top: -41px;
  table-layout: fixed;
}

.hub-dataTable-filterBar {
  transition: all 300ms ease;
  background: var(--theme-light-50, #e8eff9);
  border-bottom: 1px solid #ebebeb;
  padding: 10px 15px;
  height: auto;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  visibility: visible;
  gap: 10px;
}

.filter-hide {
  transition: all 300ms ease;
  border-bottom: none;
  opacity: 0;
  visibility: collapse;
  height: 0;
  padding: 0px 15px;
}

.hub-dataTable-toolBar {
  border-bottom: 1px solid var(--hub100);
  background-color: var(--hub25);
  padding: 0 16px;
  height: 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.hub-dataTable-tableArea {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.hub-dataTable-tableArea-buttonArea {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  height: 50px;
  margin-left: 10px;
}

.hub-dataTable-tableArea-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}

.hub-dataTable-pagination {
  height: 24px;
  display: flex;
  justify-content: flex-start;
  padding: 4px 0px;
  padding-top: 6px;
  align-items: center;
}

.hub-dataTable-pagination-new {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-page-box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
}

.pagination-page-text-box {
  min-width: 34px;
  height: 26px;
  border-radius: 8px;
  border: 1px solid #d2def2;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-page-box p {
  width: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c244c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.pagination-entries {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination-entries-text {
  color: #000;
  font-size: 11px;
  font-weight: 400;
  margin-right: auto;
}

/* TH Styles */

.hub-dataTable th {
  font-weight: 600;
  font-size: 12px;
  font-family: Open Sans, sans-serif;
  color: rgba(0, 0, 0, 0.64);
  text-align: left;
  padding: 0 8px 0 8px;
  width: auto;
  border-bottom: 1px solid var(--hub100);
}

.hub-dataTable th span {
  padding-right: 8px;
  box-sizing: border-box;
}

.hub-dataTable .th {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #061226;
  text-align: left;
  height: 40px;
  line-height: 40px;
}

.hub-dataTable th:first-child {
  padding-left: 39px;
}

.hub-dataTable .th-resize-handler {
  right: -8px !important;
  border-right: 1px solid var(--hub100);
  height: 100% !important;
  width: 8px !important;
  background: var(--hub25);
}

.hub-dataTable-headerBg {
  height: 40px;
  background: var(--hub25);
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--hub100);
}

.hub-dataTable thead {
  background-color: var(--hub25);
  height: 32px;
}

.hub-dataTable thead > th:last-child > .dtThRb {
  display: none;
}

.hub-dataTable-picker {
  width: 24px;
  padding-left: 8px;
}

.hub-dataTable-picker > input {
  border-color: rgba(0, 0, 0, 0.6);
  width: 16px;
  height: 16px;
  display: block;
  margin: 0;
}

/* TR Styles */

.hub-dataTable tr.active,
.hub-gantt-table-wrapper tr.active td {
  /*background-color: #2a80cf;
  color: rgb(255, 255, 255, 5);*/

  background: var(--hub50, #e8eff9);
  box-shadow: 0px -2px 0px 0px #1769f1 inset, 0px 2px 0px 0px #1769f1 inset;

  color: var(--hub500, #1769f1);
  border-right: 0;
  font-family: var(--hubfont);
  font-size: 13px;
  font-weight: bold !important;
  line-height: 24px;
}

.hub-dataTable tr.active .tds span a,
.hub-gantt-table-wrapper tr.active td {
  color: var(--hub500, #1769f1);
}

.hub-gantt-table-wrapper tr.green {
  background-color: #d2f0c7;
  color: #19470a;
}

.hub-gantt-table-wrapper tr.blue {
  background-color: #d5ebf5;
  color: #214352;
}

.hub-gantt-table-wrapper tr.orange {
  background-color: #fce2b3;
  color: #593e0e;
}

.hub-gantt-table-wrapper tr.navy {
  background-color: #e1e5f5;
  color: #303c78;
}

.hub-gantt-table-wrapper tr.yellow {
  background-color: #edea85;
  color: #454208;
}

.hub-gantt-table-wrapper tr.teal {
  background-color: #daf2f0;
  color: #084a44;
}

.hub-gantt-table-wrapper tr.blue {
  background-color: #d5ebf5;
  color: #214352;
}

.hub-gantt-table-wrapper tr.purple {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f9f5fd;
  color: #19470a;
}

.hub-gantt-table-wrapper tr.lightyellow {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #edfdf7;
  color: #19470a;
}

.hub-gantt-table-wrapper tr.lightteal {
  background-color: #cee9e6c7;
  color: #19470a;
}

/* TD Styles */

.hub-dataTable td,
.hub-gantt-table-wrapper td {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #051530;
  padding: 10px 15px;
  box-sizing: border-box;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  border-bottom: 1px solid var(--hub100);
  border-right: 1px solid var(--hub100);
  white-space: nowrap;
  overflow: hidden;
}

.hub-dataTable td .hoverIcon,
.hub-gantt-table-wrapper td .hoverIcon {
  display: none;
  background-color: #ffffff;
}

.hub-dataTable tr.active td:hover .hoverIcon,
.hub-gantt-table-wrapper tr.active td:hover .hoverIcon {
  background-color: #eef7ff;
}

.hub-dataTable td:hover .hoverIcon,
.hub-gantt-table-wrapper td:hover .hoverIcon {
  display: inline-block;
  padding: 0px 4px;
}

.hub-gantt-table-wrapper td .hoverIcon.active {
  display: inline-block;
  padding: 0px 4px;
}

.hub-dataTable td a,
.hub-gantt-table-wrapper td a {
  /* UI / UI Text 14 Reg */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 171% */

  letter-spacing: -0.006em;

  /* Primary / PR600 */

  color: #0e73f6;
  text-decoration: none;

  cursor: pointer;

  border-bottom: 1px solid #d9eafd;
}

.hub-dataTable td.text,
.hub-gantt-table-wrapper td.text {
  text-align: left;
}

.hub-dataTable td.date,
.hub-gantt-table-wrapper td.date {
  text-align: left;
}

.hub-dataTable td.number,
.hub-gantt-table-wrapper td.number {
  text-align: left;
}

/* Tiles */
.hub-dataTable-tileContainer {
  margin: 12px 12px;
}

/* Gantt */

.hub-gantt-table {
  flex: 1;
  overflow: auto;
}

.hub-dataTable-ganttContainer,
.hub-gantt-table-wrapper {
  margin: 0;
  border-top: 1px solid #afbacc;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.hub-gantt-table-wrapper {
  margin: 0;
  border-top: 1px solid #afbacc;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: row;
}

.hub-gantt-table-wrapper th {
  text-align: left;
  line-height: inherit;
  padding-left: 8px;
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #afbacc;
  background-color: #ffffff;
  line-height: 32px;
  box-sizing: border-box;
  white-space: nowrap;
  background-color: rgba(244, 247, 252, 1);
}

.hub-gantt-table-wrapper th .th-resize-handler {
  border-right: 1px dashed #afbacc;
}

.hub-gantt-table {
  display: flex;
  flex-direction: column;
}

.hub-gantt-table-header {
  height: 45px;
  overflow: hidden;
}

.hub-gantt-table-header > table {
  height: 45px;
}

.hub-gantt-table-body {
  flex: 1;
  overflow: auto;
}

/* gantt */

.hub-gantt {
  border-left: 2px solid #afbacc;
}

.hub-gantt-wrapper {
  display: flex;
  flex-direction: column;
}

.hub-gantt-header {
  height: 44px;
  border-bottom: 2px solid #afbacc;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  z-index: 1;
  box-shadow: 0px -1px 0px 0px rgba(210, 222, 242, 1) inset;

  box-shadow: -1px 0px 0px 0px rgba(210, 222, 242, 1) inset;

  background-color: rgba(244, 247, 252, 1);
}

.hub-gantt-header-month {
  box-sizing: border-box;

  display: flex;
  align-items: center;
  margin-right: 10px;

  font-weight: 600;

  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(6, 18, 38, 1);
  border-right: 1px solid rgba(210, 222, 242, 1);

  text-transform: uppercase;
}

.hub-gantt-body {
  flex: 1;
  overflow: auto;
}

.hub-gantt-row {
  height: 46px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hub-gantt-row.active {
  background-color: #eef7ff;
}

.hub-gantt-row-bg {
  box-sizing: border-box;
  /*border-right: 1px solid rgba(26,26,26,.15);*/
  height: 26px;
}

.hub-gantt-item {
  border: 2px solid #84b1fa;
  border-radius: 4px;
  background-color: #ffffff;
  box-sizing: border-box;
  position: absolute;
}

.hub-gantt-item-wbs {
  /* border: 2px solid #58606e; */
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: transparent;
  box-sizing: border-box;
  position: absolute;
  /*  border-width: 7px;
  border-bottom-color: transparent; */
}

.hub-gantt-item-wbs-baseline {
  border-radius: 2px;
  background-color: rgb(89 97 110 / 55%);
  box-sizing: border-box;
  position: absolute;
  top: 19px;
}

.hub-gantt-item-baseline {
  border-radius: 2px;
  background-color: #eeeeee;
  box-sizing: border-box;
  position: absolute;
  top: 19px;
}

.hub-gantt-item-baseline.green {
  background-color: #6bc84c;
}

.hub-gantt-item-baseline.blue {
  background-color: #80bed9;
}

.hub-gantt-item-baseline.brown {
  background-color: #c9beab;
}

.hub-gantt-milestone {
  border: 2px solid #84b1fa;
  background-color: #ffffff;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 2;
  transform: rotate(45deg);
}

.hub-gantt-summary {
  border: 1px solid var(--hub100, #d2def2);
  border-radius: 8px;
  background-color: #ffffff;
  box-sizing: border-box;
  position: fixed;
  min-width: 250px;
  min-height: 80px;
  z-index: 2;
  font-family: "Open Sans", Courier, monospace;
  font-size: 13px;
  padding: 15px;
  box-shadow: 0px 20px 30px 0px rgba(18, 84, 193, 0.25);

  z-index: 99;
}

.hub-gantt-summary-header {
  font-family: Noto Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgb(76, 130, 217);
  margin-bottom: 10px;
}

.hub-gantt-summary-body-wrapper {
  display: flex;
  flex-direction: column;
}

.hub-gantt-summary-body-items {
  height: 45px;
}

.hub-gantt-summary-body-item-title {
  font-family: Noto Sans;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(119, 157, 217, 1);
  height: 15px;
}

.hub-gantt-summary-body-item-value {
  font-family: Noto Sans;
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(5, 21, 48, 1);
}

.hub-gantt-milestone-baseline {
  border: 2px solid #b2c5e9;
  background-color: #b2c5e9;
  box-sizing: border-box;
  position: absolute;
  width: 16px;
  height: 16px;
  z-index: 1;
  transform: rotate(45deg);
}

.hub-gantt-item.summary {
  border-width: 6px;
  border-bottom-color: #ffffff;
}

.hub-gantt-item.blue,
.hub-gantt-milestone.blue {
  background-color: #d5ebf5;
  border-color: #80bed9;
  color: #214352;
}

.hub-gantt-item.blue.summary {
  border-width: 6px;
  border-bottom-color: #d5ebf5;
}

.hub-gantt-item.green,
.hub-gantt-milestone.green {
  background-color: #d2f0c7;
  border-color: #6bc84c;
  color: #19470a;
}

.hub-gantt-item.green.summary {
  border-width: 6px;
  border-bottom-color: #d2f0c7;
}

.hub-gantt-item.brown,
.hub-gantt-milestone.brown {
  background-color: #ede6da;
  border-color: #c9beab;
  color: #42403c;
}

.hub-gantt-item.brown.summary {
  border-width: 6px;
  border-bottom-color: #c9beab;
}

.hub-gantt-table-row-menu {
  overflow: hidden;
}

.hub-gantt-table-row-menu .iconMenu {
  position: absolute;
  background-color: #15233b;
  font-size: 14px;
  color: #ffffff;
  font-weight: normal;
  padding: 4px 16px;
  width: 160px;
  box-sizing: border-box;
  line-height: 20px;
  border-radius: 4px;
  border-top-left-radius: 0;
  cursor: pointer;
  z-index: 2;
}

/* thead sticky */

.hub-dataTable th {
  position: sticky;
  top: 0;
}

/* Context Menu */

.hub-dataTable-contextMenu {
  font-size: 14px;
  background-color: #fff;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  /* use absolute positioning  */
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;
  opacity: 1;
  transition: opacity 0.5s linear;

  min-width: 180px;
  max-height: 358px;
  background-color: #58606e;
  border: none;
  border-radius: 0;
  box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
  z-index: 1;
}

.hub-dataTable-contextMenu .item {
  color: #fff;
  padding: 3px 3px 3px 12px;
  display: block;
  border-width: 1px;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
}

.hub-dataTable-contextMenu .item:hover {
  background-color: #3d4654;
}

.hub-dataTable-contextMenu span {
  color: #fff;
  padding: 3px 3px 3px 12px;
  display: block;
  border-width: 1px;
}

.hub-datatable-actions-list {
  display: flex;
  flex-direction: column;
  width: 280px;
}

.hub-datatable-actions-list > .actions-list-items {
  margin-bottom: 8px;
}

.selectedRows {
  display: flex;
  align-items: center;
  gap: 15px;
}

.selectedRows .title-label {
  padding-left: 20px;
  color: #061226;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px; /* 261.538% */
}

.selectedRows .quick-filter-list {
  display: flex;
  gap: 10px;
}

.status-wrapper {
  display: flex;
  height: 24px;
  padding: 0 10px;
  align-items: center;
  border-radius: 12px;
}

.status-wrapper p {
  margin: 0 !important;
}

.hub-gantt-item-wbs {
  background-color: #8225fa;
  /*  border-radius: 2px 2px 0px 0px;
  background-color: transparent;
  box-sizing: border-box;
  position: absolute; */
  height: 10px;
  /* border-width: 10px; */
  /* border-bottom-color: transparent; */
}

.hub-gantt-item-activity {
  background-color: #35d69c;
  position: absolute;
  height: 4px;
}

.hub-gantt-item-task {
  background-color: rgba(23, 105, 241, 0.15);
  position: absolute;
  height: 16px;
  border-radius: 2px;
}

.hub-gantt-item-task .percentage {
  height: 100%;
  border-radius: 2px;
  background: #1769f1;
}

.hub-gantt-item-task .task-line {
  position: absolute;
  top: -4px;
  height: 2px;
  border-radius: 2px;
  background: #1769f1;
  width: 55%;
}

.hub-gantt-item-task > svg {
  position: absolute;
  bottom: -4px;
}

.bar-text {
  width: auto;
  white-space: nowrap;
  position: absolute;
  margin: 0;
  top: 50%;
  transform: translate(0, -50%);
  color: var(--hub300, #779dd9);
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
