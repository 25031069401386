.hub-ts-progress {
    background-color:#F5F7FA;
    border-radius: 4px;

}

.hub-ts-progress-completion { 
    border-radius: 4px;
    opacity: 0.1;
    background: #6716D0;
    height: 100%;
    width: 50%;
    display: flex;
}

.hub-ts-progress-body {
    margin-top:-30px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 4px 8px;
    height: 22px;
    width: 58px;
    align-items: center;
}



.hub-ts-progress-chart{ height: 22px;}
.hub-ts-progress-label {
    color: var(--hub900, #051530);
    text-align: center;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */

}