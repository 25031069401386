

.hub-projectTile-filterArea { 
    margin: 12px 15px 15px 20px;
}

.hub-projectTile-filterContainer {
    min-height: 49px;
    margin: 0;
    border: 1px solid #666666;
    background-color: #ffffff;
    width: 60%;
    border-radius: 4px;
    display: flex;
    flex-flow: row;
}

.hub-projectTile-filterContainer > .icon { 
    padding: 14px;
}
.hub-projectTile-filterContainer > .filter {width: 100%; box-sizing: border-box;}
.hub-projectTile-filterContainer > .filter > input { 
    height: 100%;
    box-sizing: border-box;
    width: 100%;
    border:0;
    outline: none;
    color: rgba(0,0,0,.87);
    font-family: Open Sans,sans-serif;
    font-size: 1rem;
    border-radius: 4px;

}

.hub-projectTile-tiles { 
    margin: 12px 0;
}

.hub-projectTile-tileBox { 
    display: inline-block;
    vertical-align: top;
    height: 320px;
    width: 370px;
}

.hub-projectTile-tile {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    /*border-color: #e899dc;*/
    background: #fff;
    height: 300px;
    width: 350px;
    text-decoration: none;
    /*position: relative;*/
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    transition: box-shadow,.2s;
    cursor: pointer;
}

.hub-projectTile-tile.pink {
 border-color: #e899dc;

}


.hub-projectTile-tile.red {
 border-color: #f59a90;

}

.hub-projectTile-tile.green {
 border-color: #6bc84c;

}
.hub-projectTile-tile.blue {
 border-color: #80bed9;

}

.hub-projectTile-tile.orange {
 border-color: #f7b440;

}

.hub-projectTile-tile.navy {
 border-color: #a8b2e6;
}

.hub-projectTile-tile.yellow {
 border-color: #ccc314;
}

.hub-projectTile-tile.teal {
 border-color: #44c7bc;
}

.hub-projectTile-tile.gray {
 border-color: #c9beab;
}


.hub-projectTile-tile:hover {
    /*border-color: #cf61bf;*/
    box-shadow: 0 2px 16px rgb(0 0 0 / 20%);
}

.hub-projectTile-tileHeader { 
    background-color: #bb96b5;
    color: #9976ad;
    height: 76px;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: border-box;
}

.hub-projectTile-tileHeader.red { 
    background-color: #fadfdc;
    color: #78180d;
   
}

.hub-projectTile-tileHeader.pink { 
    background-color: #f5dff1;
    color: #69215e;
   
}



.hub-projectTile-tileHeader.green { 
    background-color: #d2f0c7;
    color: #19470a;
   
}

.hub-projectTile-tileHeader.blue { 
    background-color: #d5ebf5;
    color: #214352;
   
}

.hub-projectTile-tileHeader.orange { 
    background-color: #fce2b3;
    color: #593e0e;
   
}

.hub-projectTile-tileHeader.navy { 
    background-color: #e1e5f5;
    color: #303c78;
   
}

.hub-projectTile-tileHeader.yellow { 
    background-color: #edea85;
    color: #454208;
   
}

.hub-projectTile-tileHeader.teal { 
    background-color: #daf2f0;
    color: #084a44;
   
}

.hub-projectTile-tileHeader.gray { 
    background-color: #ede6da;
    color: #42403c;
   
}




.hub-projectTile-tileHeader > h4 { 
    display: block;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    line-height: 24px;
    margin:0;
    margin-block-start: 10px;
    margin-top: 10px;
}

.hub-projectTile-tileHeader > span {  
    font-size: 14px;
    line-height: 20px;
    display: block;
}

.hub-projectTile-tileBody { 
    background-color: #ffffff;
    padding: 15px;
    box-sizing: border-box;
}


.hub-projectTile-tileBody > .timeline {  
    width: 100%;
    box-sizing: border-box;
    margin-top: 12px;
}




.hub-projectTile-tileBody > .timeline > .bg {  
    height: 1px;
   /* width: 100%;*/
    border-radius: 3px;
    background-color: #dde3ed;
    border: 1px solid #f5f7fa;
    position:relative;
    display: flex;
    flex-direction: row;
     flex-wrap: wrap;
     
  align-items: flex-end;
    
} 

.hub-projectTile-tileBody > .timeline > .bg>.bar {  
    border: 2px solid #6b30ca;
    background-color: #6b30ca;
    position: absolute;
    top:-2px;
    height: 1px;
    left: 0;
} 


.hub-projectTile-tileBody > .timeline >  .bg>.bar.green {  
    border: 2px solid #3f9e20;
    background-color: #3f9e20;
 
} 

.hub-projectTile-tileBody > .timeline > .bg> .bar.blue {  
    border: 2px solid #4288a6;
    background-color: #4288a6;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.orange {  
    border: 2px solid #cc8304;
    background-color: #cc8304;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.red {  
    border: 2px solid #e65d4e;
    background-color: #e65d4e;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.pink {  
    border: 2px solid #cf61bf;
    background-color: #cf61bf;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.navy {  
    border: 2px solid #6d7cc9;
    background-color: #6d7cc9;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.yellow {  
    border: 2px solid #9e9600;
    background-color: #9e9600;
 
} 

.hub-projectTile-tileBody > .timeline >  .bg>.bar.teal {  
    border: 2px solid #188f85;
    background-color: #188f85;
 
} 

.hub-projectTile-tileBody > .timeline > .bg> .bar.gray {  
    border: 2px solid #968c7b;
    background-color: #968c7b;
 
} 


.hub-projectTile-tileBody > .timeline > .bg > .milestone-area {  
    
    position: relative;
 
    /* background-clip:content-box;*/
    
 
} 


.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone {  
    
   /* background-color: #968c7b;
    left:10%;*/
    width: 14px;
    height: 14px;
    position: absolute;
    top: -9px;
    transform: rotate(-45deg);
    
 
} 


.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.not-started {  
    
   /* background-color: #968c7b;
    left:10%;*/
    background-color: #fff;
    border: 1px solid #58606e;
 
} 



.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.green {  
    
    background-color: #3f9e20;
     border: 1px solid #3f9e20;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.blue {  
    
    background-color: #4288a6;
     border: 1px solid #4288a6;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.orange {  
    
    background-color: #cc8304;
     border: 1px solid #cc8304;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.red {  
    
    background-color: #e65d4e;
     border: 1px solid #e65d4e;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.pink {  
    
    background-color: #cf61bf;
     border: 1px solid #cf61bf;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.navy {  
    
    background-color: #6d7cc9;
     border: 1px solid #6d7cc9;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.yellow {  
    
    background-color: #9e9600;
     border: 1px solid #9e9600;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.teal {  
    
    background-color: #188f85;
     border: 1px solid #188f85;
} 

.hub-projectTile-tileBody > .timeline > .bg > .milestone-area> .milestone.gray {  
    
    background-color: #968c7b;
     border: 1px solid #968c7b;
} 


.hub-projectTile-tileBody > .timeline > .labels  {  
    display: flex; 
    direction: row nowrap; 
    justify-content: space-between; 
    margin-top: 12px;

} 

.hub-projectTile-tileBody > .timeline > .labels > span {  
    text-transform: uppercase;
    color: rgba(0,0,0,.6);
    font-weight: 300;
    font-size: 16px;
} 


.hub-projectTile-metrics {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;
    margin-left: 110px;
}


.hub-projectTile-metrics > .container {
    width: 100px;
    text-align: center;
}

.hub-projectTile-metricLabel {
    color: rgb(60 60 60 / 60%);
    font-weight: 300;
    font-size: 12px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    margin: 8px 0 8px;
    text-transform: uppercase;
    width: 100px;
}

.hub-projectTile-milestone {
    margin: 12px 0 10px 12px;
    width: 60px;
    height: 60px;
    background: #ebeff5;
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.hub-projectTile-milestone > .inner{
    color: rgba(0,0,0,.6);
    line-height: 1.2em;
    text-align: center;
    transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    width: 60px;
    height: 60px;
    display: table-cell;
    vertical-align: middle;
}

.hub-projectTile-milestone > .inner  > .month{
    font-size: 11px;
    font-weight: 300;
    margin-top: -7px;
}

.hub-projectTile-milestone > .inner  > .day{
    font-size: 30px;
    font-weight: 400;
    padding-top: 5px;
}


.hub-projectTile-toolbar {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    gap: 10px;
    padding-left: 20px;
}

.hub-projectTile-toolbar > .icon {
    width: 32px;
}

