.hub-context-menu{
    position: absolute;
    background: #FFFFFF;

    border-radius: 10px;
    border: 1px solid #F6F4FB;
    background: #FFF;
    box-shadow: 0px 15px 30px 0px rgba(46, 18, 97, 0.10);
    padding: 5px 10px;
}



.hub-context-item{
    display: flex;
    flex-direction: row;
    height: 36px;
    padding: 5px 5px 5px 6px;
    align-items: center;
    box-sizing: border-box;
    color: var(--hub-900, #051530);
    width: 140px;

    leading-trim: both;

    text-edge: cap;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.hub-context-item.info {
    background: #F5F7FA;
    border: 1px solid #D0D2D5;
    border-radius: 2px;
    text-transform:none;
    height: 63px;
    width: 222px;
    align-items:center;
    margin-top: 1px;
    margin-bottom: 1px;
}

.hub-context-item:hover {
    cursor: pointer;
    background: #F5F5F8;
    border-radius: 2px;
}

.hub-context-item.info:hover {
    cursor: default;
    background: #F5F7FA;
}

.hub-context-item.red:hover {

    background: #FAF0F0;
}

.hub-context-item >.hub-context-item-icon{
  margin-right: 7.5px;
}

.hub-context-item span{
    leading-trim: both;

    text-edge: cap;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.hub-context-item.red span {
    color: #F30000;
}

.hub-context-item-line{
    margin-top: 11px;
    margin-bottom: 11px;
    background-color: rgba(0, 0, 0, 0.2) ;
    height: 1px;
   
}