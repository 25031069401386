.hub-dt-pagination-container {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .action-section {
    position: absolute;
    left: 15px;
    display: flex;
    align-items: center;
    gap: 7px;
    .page-box {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 11px;
      .page-text-box {
        min-width: 34px;
        height: 26px;
        border-radius: 8px;
        border: 1px solid #d2def2;
        background: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      p {
        width: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #0c244c;
        text-align: center;
        font-family: "Noto Sans";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
  .label-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .entries-text {
      color: #000;
      font-size: 11px;
      font-weight: 400;
      margin-right: auto;
    }
  }
}
