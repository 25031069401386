/* open-sans-300 - latin_latin-ext */
@font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 300;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-300italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 300;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-regular - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 400;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 400;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-500 - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 500;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-500italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 500;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-500italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-600 - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 600;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-600italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 600;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-700 - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 700;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-700italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 700;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-800 - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: normal;
     font-weight: 800;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-800.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800.svg#OpenSans') format('svg'); /* Legacy iOS */
   }
   /* open-sans-800italic - latin_latin-ext */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Open Sans';
     font-style: italic;
     font-weight: 800;
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.eot'); /* IE9 Compat Modes */
     src: url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.woff2') format('woff2'), /* Super Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.woff') format('woff'), /* Modern Browsers */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
          url('assets/fonts/open-sans-v34-latin_latin-ext-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
   }

/* noto-sans-100 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
@font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 100;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-100.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-100.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-100italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 100;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-100italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-100italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-200 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 200;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-200.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-200.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-200italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 200;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-200italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-200italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-300 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 300;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-300.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-300italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 300;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-300italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-300italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-regular - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 400;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-regular.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 400;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-500 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 500;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-500.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-500italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 500;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-500italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-500italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-600 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 600;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-600.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-600italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 600;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-600italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-600italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-700 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 700;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-700italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 700;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-700italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-800 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 800;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-800.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-800italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 800;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-800italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-800italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-900 - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: normal;
     font-weight: 900;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-900.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-900.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }
   /* noto-sans-900italic - cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese */
   @font-face {
     font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
     font-family: 'Noto Sans';
     font-style: italic;
     font-weight: 900;
     src: url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-900italic.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+ */
          url('assets/fonts/noto-sans-v28-cyrillic_cyrillic-ext_devanagari_greek_greek-ext_latin_latin-ext_vietnamese-900italic.woff') format('woff'); /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   }