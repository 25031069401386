.hub-sideBar-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-left: 11px;
}

.hub-sideBar-wrapper .slider {
    width: 4px;
    margin-left: 4px;
    cursor: ew-resize;
}

.hub-sideBar-wrapper .slider:hover {
    background: #5691f0;
}

.hub-sideBar {
    
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.hub-sideBar > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    box-sizing: border-box;
    height: 80px;
    border-bottom: 1px solid var(--theme-light-50, #E8EFF9);
    background: #FFF;

}

.hub-sideBar > .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
}

.hub-sideBar > .header .title {
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    max-height: 50px;
    overflow: hidden;
}


.hub-sideBar > .header .closeIcon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 6px;
    background: var(--hub50, #E8EFF9);
}

.hub-sideBar-resizer {
    justify-content: stretch;
    padding-left: 0px;
    margin-left: 0px;
    border-left: 1px solid var(--theme-light-100, #D2DEF2);
    box-shadow: 50px 50px 150px 0px rgba(12, 36, 76, 0.40);
}

.hub-sideBar-resize-handler {  
    border-right: 1px solid var(--hub100, #E8EFF9);
    border-left: 1px solid var(--hub100, #E8EFF9);
    background: var(--hub25, #F4F7FC);
    
    width: 14px !important;
    position: relative;

    background-image: url('data:image/svg+xml,<svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0C0.947715 0 0.5 0.447715 0.5 1V19C0.5 19.5523 0.947715 20 1.5 20C2.05228 20 2.5 19.5523 2.5 19V1C2.5 0.447715 2.05228 0 1.5 0ZM6.5 0C5.94772 0 5.5 0.447715 5.5 1V19C5.5 19.5523 5.94772 20 6.5 20C7.05228 20 7.5 19.5523 7.5 19V1C7.5 0.447715 7.05228 0 6.5 0Z" fill="%23D2DEF2"/></svg>');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 7px 20px;
}
.hub-sideBar-resize-handler:hover {
    background-image: url('data:image/svg+xml,<svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0C0.947715 0 0.5 0.447715 0.5 1V19C0.5 19.5523 0.947715 20 1.5 20C2.05228 20 2.5 19.5523 2.5 19V1C2.5 0.447715 2.05228 0 1.5 0ZM6.5 0C5.94772 0 5.5 0.447715 5.5 1V19C5.5 19.5523 5.94772 20 6.5 20C7.05228 20 7.5 19.5523 7.5 19V1C7.5 0.447715 7.05228 0 6.5 0Z" fill="%231769F1"/></svg>');
}


.hub-sideBar .noRows {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hub-sideBar .noRows span {
    font-size: 18px;
    color: rgba(0,0,0,.6);
    font-weight: 600;
    font-family: 'Open Sans';
}



/*
.hub-sideBar .error {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hub-sideBar .error > .label {
    font-size: 18px;
    color: rgba(0,0,0,.6);
    font-weight: 600;
    font-family: 'Open Sans';
}
*/