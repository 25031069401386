.hub-tooltipWithPage-wrapper {
  position: absolute;
  width: 320px;
  z-index: 1090;
  margin-top: 6px;
  /* left: 50%; */
  transform: translateX(-50%);
}

.hub-tooltipWithPage-wrapper .arrow {
  transform: rotate(135deg);
  border-left: 1px solid var(--hub100, #d2def2) !important;
  border-bottom: 1px solid var(--hub100, #d2def2) !important;
  background-color: #ffffff;
  height: 7px;
  width: 7px;
  display: block;
  margin-left: 48%;
  z-index: 1091;
}

.hub-tooltipWithPage-container {
  position: relative;
}

.hub-tooltipWithPage {
  background: #ffffff;
  border-radius: 8px;
  z-index: 1092;
  height: 100%;
  margin-top: -3.8px;
  border: 1px solid var(--hub100, #d2def2);
  box-shadow: 0px 20px 30px rgba(18, 84, 193, 0.25);
}

.hub-tooltipWithPage .search {
  width: 100%;
  height: 32px;
  border-radius: 6px;
  background-color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
}

.hub-tooltipWithPage .search input {
  padding-left: 32px;
  height: 32px;
  font-size: 14px;
  width: 100%;
  border-radius: 6px;
  border: solid 1px #c8d1e0;
  box-sizing: border-box;
  font-family: Open Sans;
}

.hub-tooltipWithPage .inner {
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden;
}

.hub-tooltipWithPage .inner .hub-tooltipWithPage-title {
  font-size: 14px;
  text-transform: uppercase;
  padding-top: 8px;
}

.hub-tooltipWithPage .inner .column {
  padding: 6px 12px 6px 18px;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: normal;
}

.hub-tooltipWithPage .inner .column input {
  border-color: rgba(0, 0, 0, 0.6);
  width: 16px;
  height: 16px;
  display: block;
  margin: 0;
  cursor: pointer;
}

.hub-tooltipWithPage .inner .column:hover {
  background-color: #f5f7fa;
}

.hub-tooltipWithPage .buttonBar {
  padding-top: 8px;
}
