
.hub-statusScreen {
    /*float: left;*/
   box-sizing: border-box;
    width: auto; 
    padding: 0;
  
}


.hub-statusScreen > .label {
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 4px;
   justify-content: center;
      display: flex;
}

.hub-statusScreen > .message {
    height: 20px;
    overflow: hidden;
    /*color: #c20f0f;*/
    font-size: 11px;
    line-height: 12px;
    margin: 4px 0 0;
     justify-content: center;
      display: flex;
}


.hub-statusScreen > .icon {
   
     justify-content: center;
      display: flex;
}



.hub-statusScreen > .button {
   
     justify-content: center;
      display: flex;
}

