#hub-root {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  height: 100%;
}

#hub-mainPageArea {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;
  flex: 1;
  overflow: auto;
}

.hub-page {
  background-color: #ffffff;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

body,
html,
#root {
  height: 100%;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 1.45;
}

.App {
  text-align: center;
}

.apexcharts-legend-text {
  margin-left: -12px !important; /* Adjust the space between the symbol and the text */
}
