.tooltip-search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  padding: 5px 18px;
  border-bottom: 1px solid var(--hub100, #d2def2);
  background: #fff;
  box-sizing: border-box;
  cursor: default;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transition: all 300ms ease-in-out;

  input {
    width: 100%;
    border: 0;
    outline: none;
    box-shadow: none;
    padding: 10px 10px 10px 0;
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }

  &:hover {
    border-bottom: 1px solid var(--hub300, #779dd9);
  }

  &:focus-within {
    border-bottom: 2px solid var(--hub500, #1769f1);
  }

  input::placeholder {
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    opacity: 0.15;
  }

  .close-icon {
    cursor: pointer;
  }
}
