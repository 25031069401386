.custom-checkbox {
  display: none !important;

  & + .checkbox-label {
    width: 20px;
    height: 20px;
    border: 1px solid #d2def2;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s;
    background: var(--hub0, #fff);

    &:hover {
      border: 1px solid var(--hub300, #779dd9);
    }
  }

  &:checked + .checkbox-label {
    border: 1px solid var(--hub500, #1769f1);
    background: var(--hub500, #1769f1);

    &::after {
      content: url("./check-white.svg");
      position: absolute;
      top: 1px;
      left: 1px;
    }
  }

  &:disabled + .checkbox-label {
    background: var(--hub25, #f4f7fc);
    border: 1px solid var(--hub50, #e8eff9);
    cursor: not-allowed;
  }

  &:checked:disabled + .checkbox-label {
    content: url("./check-gray.svg");
    background: var(--hub25, #f4f7fc);
    border: 1px solid var(--hub50, #e8eff9);

    &::after {
      border-color: #fff;
    }
  }
}
