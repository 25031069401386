.hub-filter-picker-wrapper {
  position: relative;
}

.hub-filter-picker {
  background: #ffffff;
  border: 1px solid rgba(74, 124, 204, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 34px;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.hub-filter-picker .label {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hub-filter-picker .label .hub-icon {
  height: 100%;
  /*  margin-right: 7px; */
}

.hub-filter-picker .menu {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  background: var(--hub25, #f4f7fc);
  padding: 0px 5px 0px 10px;
  color: #1769f1;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  height: 100%;
}

.hub-filter-picker .menu:hover {
  background: #e8eff9;
}
