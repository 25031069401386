.hub-dt-th-resizer {
  width: 3px !important;
  right: 0 !important;
/*   background-color: var(--hub500, #1769f1); */

    &:hover {
    background-color: var(--hub500, #1769f1);
   /*  border-right: 3px solid #f4f7fc;
    border-left: 3px solid #f4f7fc;
    border-bottom: 1px solid #d2def2; */
  }

  &.resizing {
    background-color: var(--hub500, #1769f1);
  /*   border-right: 3px solid #f4f7fc;
    border-left: 3px solid #f4f7fc;
    border-bottom: 1px solid #d2def2; */
  }
}

.hub-dt-th {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 11px 1px 10px;
  background: var(--hub25, #f4f7fc);
  box-shadow: -1px 0px 0px 0px #d2def2 inset, 0px -1px 0px 0px #d2def2 inset;
  overflow: hidden;
  white-space: nowrap;
  transition: all 230ms ease-in-out;
  &:hover {
    cursor: pointer;
    background: var(--hub50, #e8eff9);
  }

  .sort-default {
    opacity: 0.5;
    transition: all 300ms ease-in-out;
    padding: 6px;
    border-radius: 32px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sort-asc {
    transition: all 300ms ease-in-out;
    padding: 6px;
    border-radius: 32px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--hub25, #f4f7fc);
    }
  }

  .sort-desc {
    transition: all 300ms ease-in-out;
    transform: rotate(0.5turn);
    padding: 6px;
    border-radius: 32px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: var(--hub25, #f4f7fc);
    }
  }
}

.dragging-header {
  border: 1px solid var(--hub500, #1769f1);
}
