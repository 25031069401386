.hub-pageNavigation {
    transition: all .5s ease-in-out;
    border-bottom: 1px solid var(--hub100);
    height: 53px;
    display: flex;
    flex-flow: row;
    justify-content: stretch;
    
}


.hub-pageNavigation > .link {
    height: 54px;
    display: flex;
    flex-direction: column;
} 

.hub-pageNavigation > .link > .body {
flex: 1;
line-height: 51px;
}

.hub-pageNavigation > .link > .body >  a{
    font-style: normal;
    font-weight: var(--semibold);
    font-size: var(--size-md);
    text-decoration: none;
    color: #9DA9BD;
    box-sizing: border-box;
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;
    cursor: pointer;

    transition: all .3s ease-out;
} 


.hub-pageNavigation > .link > .body > a:hover { color: #000000; }



.hub-pageNavigation > .link > .border {
    background-color: var(--hub100);
    height: 1px;
    display: block;
    border-radius: 12px;
}

.hub-pageNavigation > .link.active > .border {
    background-color: var(--hub500);
    height: 3px;
    display: block;
}


.hub-pageNavigation > .link.active > .body > a {
    color: var(--hub500);
}


/*
İleride kullanabiliriz, o yüzden silmiyorum.


.hub-pageNavigation.red { 
    background-color: #fadfdc;
    color: #78180d;
   
}

.hub-pageNavigation.pink { 
    background-color: #f5dff1;
    color: #69215e;
   
}



.hub-pageNavigation.green { 
    background-color: #d2f0c7;
    color: #19470a;
   
}

.hub-pageNavigation.blue { 
    background-color: #d5ebf5;
    color: #214352;
   
}

.hub-pageNavigation.orange { 
    background-color: #fce2b3;
    color: #593e0e;
   
}

.hub-pageNavigation.navy { 
    background-color: #e1e5f5;
    color: #303c78;
   
}

.hub-pageNavigation.yellow { 
    background-color: #edea85;
    color: #454208;
   
}

.hub-pageNavigation.teal { 
    background-color: #daf2f0;
    color: #084a44;
   
}

.hub-pageNavigation.gray { 
    background-color: #ede6da;
    color: #42403c;
   
}


.hub-pageNavigation > .link.active.red { 
   
    border-bottom:  3px solid #78180d;
   
}

.hub-pageNavigation > .link.active.pink { 
   
    border-bottom:  3px solid #69215e;
   
}



.hub-pageNavigation > .link.active.green { 
   
    border-bottom:  3px solid #19470a;
   
}

.hub-pageNavigation > .link.active.blue { 
   
    border-bottom:  3px solid #214352;
   
}

.hub-pageNavigation > .link.active.orange { 
   
    border-bottom:  3px solid #593e0e;
   
}

.hub-pageNavigation > .link.active.navy { 
   
    border-bottom:  3px solid #303c78;
   
}

.hub-pageNavigation > .link.active.yellow { 
   
    border-bottom:  3px solid #454208;
   
}

.hub-pageNavigation > .link.active.teal { 
   
    border-bottom:  3px solid #084a44;
   
}

.hub-pageNavigation > .link.active.gray { 
   
    border-bottom:  3px solid #42403c;
   
}


*/
