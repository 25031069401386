.hub-toolbar-container {
  border-bottom: 1px solid var(--hub100);
  background-color: var(--hub25);
  padding: 0 10px 0 14px;
  min-height: 64px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}
