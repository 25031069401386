.hier-table-wrapper {
  margin-top: 15px;
  max-height: 350px;
  overflow: auto;
  padding: 0 8px 8px 8px;
}

.hier-item-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border-radius: 3px;
}

.hier-item-wrapper .team-label {
  width: 100%;
  cursor: pointer;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 271.429% */
}

.hier-item-wrapper .expand-icon {
  cursor: pointer;
}

.hier-item-wrapper.selected {
  border-radius: 3px;
  background: var(--hub500, #1769f1);
}

.hier-item-wrapper.selected .team-label {
  color: #fff;
}

.hier-item-wrapper:not(.selected):hover {
  cursor: pointer;
  background: var(--hub25, #f4f7fc);
}
