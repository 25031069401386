.hub-cmn-badge-wrapper { position: relative;}

.hub-cmn-badge-wrapper > .badge { 
    box-sizing: border-box;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;

    border-radius: 10px;

    display: flex;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    place-content: center;
    -webkit-box-align: center;
    align-items: center;

    padding: 0 5px;

    position: absolute;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    z-index: 1;

    background-color: #4371D1;
    color: #FFFFFF;
    top: 0px;
    right: 0px;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
 }
