:root {
		/** Hub Main Colors *******************************/
		--hub25:  #F4F7FC; /** Light ++++++ **/
		--hub50:  #E8EFF9; /** Light +++++ **/
		--hub100: #D2DEF2; /** Light ++++ **/
		--hub200: #A5BDE5; /** Light +++ **/
		--hub300: #779DD9; /** Light ++ **/
		--hub400: #4A7CCC; /** Light + **/
		--hub500: #1769F1; /** Main **/
		--hub600: #1254C1; /** Dark + **/
		--hub700: #0E3F91; /** Dark ++ **/
		--hub800: #092A60; /** Dark +++ **/
		--hub900: #051530; /** Dark ++++ **/
		/** Hub Secondary Colors **************************/
		--blue: #0d6efd;
		--indigo: #6610f2;
		--purple: #6f42c1;
		--pink: #d63384;
		--red: #dc3545;
		--orange: #fd7e14;
		--yellow: #ffc107;
		--green: #198754;
		--teal: #20c997;
		--cyan: #0dcaf0;
		--white: #fff;
		--gray: #6c757d;
		--gray-dark: #343a40;
		--primary: #0d6efd;
		--secondary: #6c757d;
		--success: #198754;
		--info: #0dcaf0;
		--warning: #ffc107;
		--danger: #dc3545;
		--light: #f8f9fa;
		--dark: #212529;
		/** Border Radius *********************************/
		--radius-sm: 6px;
		--radius-md: 8px;
		--radius-lg: 12px;
		--radius-cc: 50%;
		/** Typography ************************************/
        --hubfont: 'Noto Sans', Open-Sans, sans-serif;
		--size-xsm: 12px;
		--size-sm: 13px;
		--size-md: 14px;
		--size-lg: 15px;
		--size-xlg: 20px;
		--regular: 400;
		--medium: 500;
		--semibold: 600;
		--bold: 700;
	}
    * {font-family: var(--hubfont);}