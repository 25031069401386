.widget-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid var(--hub50, #e8eff9);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(6, 18, 38, 0.02);
  .widget-title {
    color: #000;
    font-family: "Noto Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    padding: 5px 0;
  }

  .child-list {
    display: flex;
    gap: 30px;
  }
}
