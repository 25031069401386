.upload-button-wrapper {
  margin-left: 0px !important;
  position: relative;
  .upload-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f9fafc;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
      outline: 1px solid var(--hub100, #d2def2);
      background: var(--white, #fff);
    }
  }

  .upload-progress-circle-wrapper {
    position: absolute;
    top: -2px;
    z-index: -2;
    left: -2px;
    .circle {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      animation: progress 2s 1 forwards;
    }
  }
}
