
.hub-textField {
    float: left;
    padding-right: 32px;
    box-sizing: border-box;
    margin-bottom:16px;
}

.hub-textField > .label {
    color: rgba(0,0,0,.6);
    text-transform: none;
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
    height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 0 4px;
    display: block;
}

.hub-textField > .formInput {
    width: 100%;
    padding: 3px 8px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;
    transition: all 0.5s;
}

.hub-textField > .formInput.disabled {
    border: 1px solid rgba(0,0,0,.15);
    background: #f5f7fa;
    cursor: not-allowed;
}

.hub-textField > .formInput > input, .hub-textField > .formInput > textarea {
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    font-family: 'Open Sans';
    line-height: 24px;
    color: rgba(0,0,0,.87);
    padding: 0;
    background-color: transparent;
    transition: all 0.5s;
    overflow-x: hidden;
    text-overflow: ellipsis;
}