.hub-dataTable-filterItem {
  margin-left: 8px;
  display: flex;
  background-color: #cfe0fc;
  border-radius: 4px;
}

.hub-dataTable-filterItem .text {
  cursor: pointer;
  line-height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hub-dataTable-filterItem .text .icon {
  color: #114599;
  margin-right: 4px;
  height: 12px;
}

.hub-dataTable-filterItem .text .attribute {
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  color: #15233b;
  margin-right: 4px;
  font-weight: 600;
}

.hub-dataTable-filterItem .text .expression {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.hub-dataTable-filterItem .close {
  border-left: 1px solid #f0f5fc;
  padding: 0 6px;
  border-radius: 0 4px 4px 0;
  display: block;
  line-height: 24px;
  outline: none;
  color: #114599;
  cursor: pointer;
}

.hub-dataTable-filterItem .close:hover {
  background-color: #f0f5fc;
}
