.hub-dashboard-page-container {
  background: #f5f7fa;
  padding: 30px;
}

.hub-dashboard-page-container-header {
  background: #e8ecf3;
  /*opacity: 0.05;*/
  border-radius: 7px;
  height: 60px;
  margin-top: 18px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15px;
}

.hub-dashboard-page-container-header > .container-header-icon {
  margin-right: 15px;
  background: #1d5bbf;
  border-radius: 6px;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hub-dashboard-page-container-header > .container-header-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #1d5bbf;
  opacity: 1;
}
