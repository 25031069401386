.block-list-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.block-list-table {
    padding: 12px
}

.block-list-table tr {
    height: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.block-list-table th{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    color: #BDBDBD;
}

.block-list-table td {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;

}


.go-back{
    margin-left: 16px;
    margin-bottom: 10px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;

    height: 20px;
    box-sizing: border-box;

    align-items: center;

    color: #0E73F6;
    cursor: pointer;
    border-bottom: 1px solid #D9EAFD;
    width: 127px;
}
.block-details-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header-line{
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    
}

.header-line span {
    font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        margin-right: 5px;
        color: #000000;

}
.line {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    height: 1px;

}


.description-area {
    margin-left: 5%;
}

.description-area>.description{
    display: flex;
    flex-direction: column;
    height: 175px;
}

.buttons{
    display: flex;
    float: right;
    padding: 16px;
}


.summary {
    padding: 12px;
    padding-bottom: 0;
}

.summary >.header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */

    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.7);

    opacity: 0.9;
    height: 50px;
}

.block-count-area{
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    justify-content: flex-end;
}



.block-count{
    margin-right: 50px;
}

.block-count>.block-count-key {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: #000000;
}

.block-count>.block-count-value {
    font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
  
    
        color: rgba(0, 0, 0, 0.7);
    
        opacity: 0.9;
}


.block-list-filter{
    height: 100px;
 
}

.filter-buttons{
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    align-items: center;
}