.hub-loginPage {
    background-color: #1d5bbf;
    background: linear-gradient(180deg,#5691f0,#103570);
    min-height: 100%;
    min-height: 100vh;
    display: -ms-flexbox;
    -ms-flex-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1px;
}

.hub-loginWrapper {
    width: 480px;
}
.hub-loginLogo {
    text-align: center;
}

.hub-login {
    position: relative;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    background: #fff;
    padding: 30px;
    border: 1px rgba(0,0,0,.15);
    box-shadow: 0 2px 16px rgb(0 0 0 / 20%), 0 4px 32px rgb(0 0 0 / 20%);
    margin: 16px auto;
}
