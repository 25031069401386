.hub-dataTable-filterItem-wrapper {
  height: 34px;
}

.hub-dataTable-filterItem {
  height: 100%;
  margin-left: 0px !important; 
  background-color: var(--white) !important;
  border-radius: 6px;
  border: 1px solid var(--hub100) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
}

.hub-dataTable-filterItem .text {
  cursor: pointer;
  line-height: 24px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hub-dataTable-filterItem .text .icon {
  color: #114599;
  margin-right: 4px;
  height: 12px;
}

.hub-dataTable-filterItem .text .attribute {
  line-height: 24px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  align-items: center;
  color: var(--hub900) !important;
  margin-right: 4px;
  font-weight: 700;
}

.hub-dataTable-filterItem .text .expression {
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-right: 5px;
}

.hub-dataTable-filterItem .close {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 2px !important;
  border-radius: 50% !important;
  width: 14px;
  height: 14px;
  outline: none;
  cursor: pointer;
  background-color: var(--hub50);
  transition: all 300ms ease-in-out;
}

.hub-dataTable-filterItem .close:hover {
  background-color: #f0f5fc;
}

.hub-dataTable-filterItem .arrow-down {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.option-indicator {
  color: var(--hub300, #779dd9);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}

.filter-value {
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
}
