.file-badge-container {
  box-sizing: border-box;
  display: flex;
  width: fit-content;
  height: 25px;
  gap: 5px;
  padding: 0px 6px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid var(--hub50, #e8eff9);
  background: var(--white #fff);
  cursor: pointer;
  p {
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 14.3px */
    padding: 0 !important;
    margin: 0 !important;
  }

  .icon {
    &:hover {
      opacity: 0.75;
    }
  }
}
