
.hub-dataTable-filterItem-form-wrapper {
    position: absolute;

    color: rgba(0,0,0,.87);
    font-weight: 400;

    box-sizing: border-box;
    margin: 4px 0 0 8px;
    padding: 4px 0px;


    min-width: 260px;
    width: 320px;
    max-height: 358px;
    overflow: visible;

    z-index: 10;
    background: #fff;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    outline: none;
}

.hub-dataTable-filterItem-form-row {
    padding: 4px 8px 4px 8px;
    display: flex;
    align-content: stretch;
    gap: 16px;
}

.hub-dataTable-filterItem-form-input {
    border: 1px solid rgba(0,0,0,.15);
    width: 100%;
    box-sizing: border-box;
    height: 30px;
    border-radius: 4px;
    font-family: "Open Sans";
    font-size: 14px;
}