.hub-dataTable-prebuiltFilter {
  display: flex;
  height: 34px;
  padding: 0px 10px 0px 7px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: 6px;
  border: 1px solid #d2def2;
  background-color: transparent;
  cursor: pointer;
  transition: all 300ms ease-in;
}

.hub-dataTable-prebuiltFilter:hover {
  background: var(--theme-light-0, #FFF);
  border: 1px solid var(--theme-light-100, #D2DEF2);
}

.selected-filter {
  border: 1px solid var(--theme-light-100, #D2DEF2);
  background: var(--theme-light-0, #FFF);
}

.hub-dataTable-prebuiltFilter .label {
  color: #0c244c;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 250% */
  opacity: 0.87;
}

.hub-dataTable-prebuiltFilter .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 300ms ease-out;
}

.hub-dataTable-prebuiltFilter.red span {
  background-color: #fadfdc;
  color: #78180d;
}

.hub-dataTable-prebuiltFilter.pink span {
  background-color: #f5dff1;
  color: #69215e;
}

.hub-dataTable-prebuiltFilter.green span {
  background-color: #d2f0c7;
  color: #19470a;
}

.hub-dataTable-prebuiltFilter.blue span {
  background-color: #d5ebf5;
  color: #214352;
}

.hub-dataTable-prebuiltFilter.orange span {
  background-color: #fce2b3;
  color: #593e0e;
}

.hub-dataTable-prebuiltFilter.navy span {
  background-color: #e1e5f5;
  color: #303c78;
}

.hub-dataTable-prebuiltFilter.yellow span {
  background-color: #edea85;
  color: #454208;
}

.hub-dataTable-prebuiltFilter.teal span {
  background-color: #daf2f0;
  color: #084a44;
}

.hub-dataTable-prebuiltFilter.gray span {
  background-color: #ede6da;
  color: #42403c;
}
