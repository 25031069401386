.hub-progress-circle-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hub-progress-circle {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 5px;
  background: radial-gradient(closest-side, white 59%, transparent 80% 100%),
    conic-gradient(hotpink 25%, pink 0);

  animation: progress 2s 1 forwards;
}
