.hub-teamHub-card {
  border-radius: 12px;
  background: #ffffff;
  border: 1px solid #e0e8f3;
  box-shadow: 0px 1px 2px rgba(6, 18, 38, 0.05);
  border-radius: 12px;
  margin-bottom: 15px;
  width: 300px;
  box-sizing: border-box;
  transition: all 230ms ease-in-out;
}

.hub-teamHub-card.selected {
  outline: 2px solid var(--hub500, #1769f1);
  box-shadow: 0px 20px 30px 0px rgba(6, 18, 38, 0.1);
}

.hub-teamHub-card > .body {
  display: flex;
  flex-direction: column;
  padding: 18px;
}

.hub-teamHub-card > .body .title {
  color: #0c244c;
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  word-break: break-word;
}

.hub-teamHub-card > .body .project-title {
  font-family: "Open Sans";
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  word-break: break-word;
  margin-bottom: -10px;
}

.hub-teamHub-card > .body > .details {
  height: 18px;
  margin: 15px 0;
}

.hub-teamHub-card > .body > .details > .tag {
  display: inline-block;
  box-sizing: border-box;
}

.hub-teamHub-card > .body > .details > .tag > a {
  cursor: pointer;

  padding: 0px 5px 0px 5px;
  box-sizing: border-box;
  border-radius: 5px;

  color: #fff;
  background-color: red;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 18px;
  text-decoration: none;

  display: inline-block;
  align-items: center;
}

.hub-teamHub-card > .body > .details > .tag > a.green {
  background-color: #d2f0c7;
  color: #19470a;
}
.hub-teamHub-card > .body > .details > .tag > a.blue {
  background-color: #d5ebf5;
  color: #214352;
}
.hub-teamHub-card > .body > .details > .tag > a.orange {
  background-color: #fce2b3;
  color: #593e0e;
}
.hub-teamHub-card > .body > .details > .tag > a.navy {
  background-color: #e1e5f5;
  color: #303c78;
}
.hub-teamHub-card > .body > .details > .tag > a.yellow {
  background-color: #edea85;
  color: #454208;
}
.hub-teamHub-card > .body > .details > .tag > a.teal {
  background-color: #daf2f0;
  color: #084a44;
}
.hub-teamHub-card > .body > .details > .tag > a.gray {
  background-color: #ede6da;
  color: #42403c;
}
.hub-teamHub-card > .body > .details > .tag > a.lightblue {
  background-color: #f4f7fc;
  color: #4a7ccc;
}
.hub-teamHub-card > .body > .details > .tag > a.red {
  background-color: #ea2246;
  color: #ffffff;
}

.hub-teamHub-card > .body > .toolbar {
  min-height: 29px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  height: auto;
  position: relative;
}

.hub-teamHub-card > .body > .toolbar > .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 140px;
  transition: all ease-in 0.2s;
  overflow: hidden;
  position: absolute;
  z-index: 5;
}

.hub-teamHub-card > .body > .toolbar > .right {
  flex: 1;
}

/* avatarlar */

.avatar {
  position: relative;
  transition: all 500ms ease-in-out;
}

.avatar.new {
  width: 100%;
  height: 100%;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background: #f4f7fc !important;
  margin-left: 0px !important;
}

.avatar.new:hover {
  background: #1d5bbf !important;
}

.avatar.active:not(.new) {
  outline: 1px solid var(--hub400, #4a7ccc) !important;
  background-color: #1d5bbf !important;
}

.avatar.active.new {
  background-color: #1d5bbf !important;
}

.avatar-group.active {
  display: flex;
  flex-direction: row-reverse;
  gap: 1px;
}

.avatar-group.active > .avatar {
  margin-left: 0;
  cursor: pointer;
}

.avatar-group {
  display: inline-block;
  height: auto;
  justify-content: flex-start;
  float: right;
  position: relative;
}

.avatar-group > .avatar {
  float: right;
  margin-left: -7px;
  width: 28px !important;
  height: 28px;
  position: relative;
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  /*  border: 2px solid #ffffff; */

  /* z-index:0;*/

  background-color: #fff;
  transition: all 0.2s;
  font-size: smaller;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.avatar > .avatar-wrapper {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
  .avatar-group > .avatar.new > span {
  color: #1d5bbf;

  font-weight: 400;
  /**/
  font-size: 24px;
}

/** YENI dosYa sonu **/

.hub-teamHub-card > .body > .card-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.hub-teamHub-card > .body > .card-toolbar > .assignmets {
  cursor: pointer;
}

.hub-teamHub-card > .body > .card-toolbar > .actions {
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  grid-gap: 10px;
  justify-content: space-around;
  cursor: pointer;
  align-items: center;
}

.card > .body > .card-toolbar > .actions > .items {
  margin-right: 10px;
}

.card > .body > .card-toolbar .ratio {
  color: #212121;
  font-size: 14px;
  font-weight: bold;
  background-color: #cccccc;
  padding: 0px 4px;
  border-radius: 6px;
}

.card > .body > .header .mark.red {
  background-color: #f59a90;
}
.card > .body > .header .mark.green {
  background-color: #6bc84c;
}
.card > .body > .header .mark.blue {
  background-color: #80bed9;
}
.card > .body > .header .mark.orange {
  background-color: #f7b440;
}
.card > .body > .header .mark.navy {
  background-color: #a8b2e6;
}
.card > .body > .header .mark.yellow {
  background-color: #ccc314;
}
.card > .body > .header .mark.teal {
  background-color: #44c7bc;
}
.card > .body > .header .mark.gray {
  background-color: #c9beab;
}

.card > .body > .header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 48px;
}

.card > .body > .header > .header-toolbar {
  width: 24px;
}
.card > .body > .header > .header-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-self: flex-start;
}

.card > .body > .header > .header-body > .code {
  font-size: 12px;
  border: 1px solid #d9d9d9;
  padding: 4px 6px;
  border-radius: 5px;
  color: #212121;
}

.card > .body > .header > .header-body > .project-code > a {
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  padding: 4px 6px 4px 6px;
  color: #fff;
  background-color: red;
  text-decoration: none;
}

.card > .body > .info {
  display: flex;
  flex-direction: column;
  margin-top: 3px;
}

.card > .body > .info > .item {
  display: flex;
  flex-direction: row;
}

.card > .body > .info > .item > h4 {
  margin: 0;
}

.card > .body > .info > .item > span {
  margin-left: 2px;
}
