

.hub-card-tag-container{
    margin-top: 3px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
   
}



.hub-card-tag-body{
    
    padding: 0px 5px;
    height: 20px;
    overflow: hidden;
    width: auto;

    background: #3368D6;
    border-radius: 4px;
    margin-right: 5px;   
    transition: width 2s;
}



.hub-card-tag-body.collapsed {

 
    width: 3px;
    padding: 0;
    border-radius: 4px;
}


/*
.hub-card-tag-container:hover > .hub-card-tag-body.collapsed{
    width: auto;
    transition: width 2s;
    padding: 0px 5px;
}
*/
 .hub-card-tag-body.collapsed .hub-card-tag-label {
    display: none;
}
/*
.hub-card-tag-container:hover >.hub-card-tag-body.collapsed> .hub-card-tag-label {
    display: block;
}
*/

.hub-card-tag-tooltip-wrapper{
    display: none;
}
.hub-card-tag-container:hover > .hub-card-tag-tooltip-wrapper{
    display: block;
}


.hub-card-tag-label{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    color: #FFFFFF;
}

.hub-card-tag-body.green {
    background-color: #377B58;
    color: #19470a;
}

.hub-card-tag-body.blue {
    background-color: #3368D6;
    color: #ffffff;
}

.hub-card-tag-body.orange {
    background-color: #B15027;
    color: #593e0e;
}

.hub-card-tag-body.navy {
    background-color: #e1e5f5;
    color: #303c78;
}

.hub-card-tag-body.yellow {
    background-color: #EDC748 ;
    color: #454208;
}

.hub-card-tag-body.teal {
    background-color: #4BA664;
    color: #ffffff;
}

.hub-card-tag-body.gray {
    background-color: #607180;
    color: #ffffff;
}

.hub-card-tag-body.lightblue {
    background-color: #f4f7fc;
    color: #4a7ccc;
}

.hub-card-tag-body.red {
    background-color: #DC3545;
    color: #ffffff;
}
.hub-card-tag-body.lime {
    background-color: #4D7C26;
    color: #ffffff;
}

.hub-card-tag-body.turquoise  {
    background-color: #35788E;
    color: #ffffff;
}

.hub-card-tag-body.aqua {
    background-color: #3171B5;
    color: #ffffff;
}


.hub-card-tag-body.ultramarine {
    background-color: #565FE0;
    color: #ffffff;
}

.hub-card-tag-body.purple {
    background-color: #864DD6      ;
    color: #ffffff;
}

.hub-card-tag-body.pink {
    background-color: #BB328F      ;
    color: #ffffff;
}
