.hub-rte-wrapper {
  position: relative;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid var(--hub100, #d2def2);
  transition: all 230ms ease-in-out;
  &.focused {
    border: 1px solid var(--hub500, #1769f1) !important;
    outline: none; /* Remove default outline */
  }

  &:not(.focused):hover {
    border: 1px solid #779dd9;
  }

  .edit-label {
    position: absolute;
    top: 17px;
    left: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
    p {
      margin: 0 !important;
      padding: 0 !important;
      color: #647795;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 18.2px */
    }
  }
}

.hub-rte-editable {
  box-sizing: border-box;
  border-radius: 6px;
  background-color: #fff;
  height: auto;
  min-height: 68px !important;
  max-height: 140px;
  overflow: hidden;
  caret-color: var(--hub500, #1769f1);
  color: var(--hub900, #051530);
  overflow-y: auto;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  transition: all 230ms ease-in-out;
  &:focus {
    outline: none; /* Remove default outline */
  }
  &::-webkit-scrollbar {
    width: 18px;
    height: 18px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #e3e3e3;
    border-radius: 20px;
    padding: 1px;
    border: 7px solid transparent;
    background-clip: padding-box;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    /* background: #767c85; */
    border-radius: 100px;
    padding: 2px;
    border: 5px solid transparent;
    background-clip: padding-box;
  }
}

.hub-rte-editable.shrinked {
  height: 68px;
  min-height: 68px !important;
  max-height: 68px !important;
  padding: 15px 95px 25px 15px !important;
  overflow: hidden;
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.hub-rte-toolbar {
  box-sizing: border-box;
  width: calc(100% - 30px);
  height: 65px;
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  margin: 0 15px !important;
  padding: 0 !important;
  border-bottom: none !important;
  border-top: 1px solid #e8eff9;
  background-color: #fff;
}

.hub-rte-button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #f9fafc;
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-left: 0px !important;
}

.hub-rte-button.selected {
  background: var(--hub500, #1769f1);

  .hub-rte-icon {
    svg {
      path {
        stroke: var(--white, #fff);
      }
    }
  }
}

.hub-rte-button:not(.selected):hover {
  outline: 1px solid var(--hub100, #d2def2);
  background: var(--white, #fff);
}

.hub-rte-mention-options {
  padding: 5px;
  display: flex;
  flex-direction: column;
  max-height: 180px;
  overflow: auto;
  .hub-rte-mention-item {
    display: flex;
    height: 32px;
    padding: 0px 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px;
    border-radius: 3px;
  }
}

.hub-rte-mention-item.selected {
  border-radius: 3px;
  background: var(--hub500, #1769f1);
  color: #fff;
}

.hub-rte-mention-item:not(.selected):hover {
  cursor: pointer;
  background: var(--hub25, #f4f7fc);
}

.hub-rte-action {
  position: absolute;
  right: 0;
  display: flex !important;
  gap: 6px;
}

.hub-rte-mention-badge {
  display: inline-block;
  height: 17px;
  padding: 0 2px;
  border-radius: 2px;
  color: var(--hub500, #176af12a);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  .mention-icon {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    margin-right: 2px;
  }
}

.hub-rte-placeholder {
  display: flex;
  align-items: center;
  color: var(--hub900, #051530) !important;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  padding-top: 15px !important;
  opacity: 1 !important;
}

.hub-rte-placeholder.focused {
  color: #647795 !important;
}

.enter-shortcut {
  color: #647795;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  margin-left: 10px;
  margin-right: 4px;
}

.hub-rte-file-list {
  padding: 15px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .file-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: fit-content;
  }
  .file-settings {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f4f7fc;
    padding: 0;
    border: 1px solid #fff;
    .icon span {
      transform: rotate(90deg);
    }
  }

  .file-settings:hover {
    border: 1px solid #a5bde5;
  }

  .file-more-wrapper {
    position: relative;
  }

  .file-options-menu {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    width: 204px;
    height: auto;
    right: 0px;
    top: 20px;
    z-index: 10500;
    background: #ffffff;
    border: 1px solid #d2def2;
    box-shadow: 0px 20px 30px 0px rgba(18, 84, 193, 0.25);
    border-radius: 6px 0 6px 6px;
  }

  .file-options-menu-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
    width: 202px;
    height: 40px;
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .delete-icon {
      position: absolute;
      right: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.75;
      }
    }
  }

  .file-menu-divider {
    width: 202px;
    height: 2px;
    background: #f4f7fc;
  }
}
