.hub-dt-colorfull-content {
  display: flex;
  height: 24px;
  width: fit-content;
  padding: 0 10px;
  align-items: center;
  border-radius: 12px;
  .text {
    margin: 0 !important;
    font-family: "Noto Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
