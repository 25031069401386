.hub-rmUtil-modalWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1199;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.hub-rmUtil-modal {
    width: 1010px;
    height: 596px;

    background: #F5F7FA;
    margin: 100px auto;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
}  

.hub-rmUtil-modalHeader {
    display: flex;
    flex-direction: row;
    padding: 13px 16px;
    justify-content: space-between;

    background: #F5F7FA;
    box-shadow: 0px 1px 0px #E5E5E5;
    border-radius: 10px 10px 0px 0px;

    border-bottom: 1px solid #e5e5e5;

}

.hub-rmUtil-modalHeader .hub-button {
    height: 38px;
    line-height: 36px;
}


.hub-rmUtil-modalHeader .hub-button.tiny-icon {
    line-height: 30px !important;
}


.hub-rmUtil-modalHeader-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}


.hub-rmUtil-modalHeader-title {
    font-weight: 600;
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.hub-rmUtil-modalHeader-title input {

    box-sizing: border-box;
    height: 38px;
    width: 270px;
    padding-left: 11px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 38px;


    background: #E8EDF5;
    border-radius: 6px;
    border:0;

    transition: border ease-in-out 0.1s;

}


.hub-rmUtil-modalBody {
    overflow: auto;
    height: 532px;
    display: flex;
    flex-direction: row;
    flex:1;
}


.hub-rmUtil-modalBody .columnHeader {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */

    color: #000000;
}

.hub-rmUtil-modalBody .columnDescription {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #5B5B5B;
    margin-top: 3px;
}

.hub-rmUtil-modalBody .left {
    width: 488px;
    height: 100%;
    padding: 40px 60px;
    box-sizing: border-box;
}

.hub-rmUtil-modalBody .right {
    width: 518px;
    height: 100%;
    border-left: 4px solid #E2E8F1;
    padding: 40px 74px 64px 64px;
    box-sizing: border-box;
}



.hub-rmUtil-modalBody-button-area {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-bottom: 10px;
    margin-top: auto;
}

.hub-rmUtil-layoutModalLevel {
    display: flex;
    height: 50px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    margin-top: 10px;
    transition: opacity ease-in 0.3s;
}


.hub-rmUtil-layoutModalLevel .reorder {
    width: 30px;
    height: 50px;
    box-sizing: border-box;
    padding: 3px;
    
    background: #FFFFFF;
    box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
} 
.hub-rmUtil-layoutModalLevel .reorder div{
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

}

.hub-rmUtil-layoutModalLevel .name {
        flex: 1;
        height: 50px;
        box-sizing: border-box;
        padding-left: 17px;

        background: #FFFFFF;
        box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.11);
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: left;
        gap: 13px;

        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        
        color: #202A39;    
        cursor: pointer;
    
} 
.hub-rmUtil-layoutModalLevel .toggle {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        
        background: #FFFFFF;
        box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.11);
        border-radius: 6px;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
} 

.hub-rmUtil-modalBody .right .fields {
    display: flex;
    gap: 0px;
    margin-top: 45px;
}

.hub-rmUtil-modalBody .right .fields .fieldColumn {
    width: 180px;
}

.hub-rmUtil-modalBody .right .fields .fieldColumnLink {
    width: 20px;
}

.hub-rmUtil-modalBody .right .fields .fieldColumnLink .linkWrapper{
    width: 20px;
    height: 30px;
    display: flex;
    position: relative;
}

.hub-rmUtil-modalBody .right .fields .fieldColumnLink .leftLink {
    box-sizing: border-box;
    width: 11px;
    border: 2px solid #1D5BBF;
}

.hub-rmUtil-modalBody .right .fields .fieldColumnLink .rightLink {
    box-sizing: border-box;
    width: 11px;
    margin-left: -2px;
    border: 2px solid #1D5BBF;
    
}





.hub-rmUtil-modalBody .right .fields .fieldColumn .fieldColumnHeader {
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    color: #1D5BBF;

}


.hub-rmUtil-modalBody .right .fields .fieldColumn .fieldList {
    margin-top: 25px;
    width: 180px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hub-rmUtil-modalBody .right .fields .fieldColumn .fieldList .placeholder {
    width: 180px;
    height: 290px;
    background: #ECF0F6;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.hub-rmUtil-modalBody .right .fields .fieldColumn .icon {
    width: 30px;
    height: 30px;
    background-color: #fff;;
    background: #FFFFFF;
    box-shadow: 0px 5px 5px -5px rgba(93, 118, 157, 0.22);
    border-radius: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.hub-rmUtil-modalBody .right .fields .fieldColumn .fieldList .placeholder .label {
    width: 100px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 17px;
    /* or 142% */

    text-align: center;

    /* Hub Blue 500 */

    color: #1D5BBF;
}

.hub-rmUtil-modalBody .right .fields .fieldColumn .field {
        height: 50px;
        box-sizing: border-box;
        padding-left: 20px;

        background: #FFFFFF;
        box-shadow: 0px 5px 5px -5px rgba(93, 118, 157, 0.22);
        border-radius: 6px;


        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 50px;
        
        color: #202A39;    
        cursor: pointer;

}

.hub-rmUtil-modalBody .right .fields .fieldColumn .field.active {
    border: 2px solid #4173D2;
    color: #1D5BBF;
    line-height: 47px;
    transition: all 0.1s;
}

.hub-rmUtil-modalBody .right .fields .fieldColumn .field.disabled {
    cursor: inherit;
    opacity: 0.3;
    transition: all 0.1s;
}