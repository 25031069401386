.hub-tag-body {
    padding: 0px 5px;
    height: 20px;
    overflow: hidden;
    width: auto;
  
    background: #3368d6;
    border-radius: 4px;
    margin-right: 5px;
    transition: width 2s;
  }
  
  .hub-tag-body.collapsed {
    width: 3px;
    padding: 0;
    border-radius: 4px;
  }
  
  .hub-tag-body.collapsed .hub-card-tag-label {
    display: none;
  }
  
  .hub-card-tag-tooltip-wrapper {
    display: none;
  }
  .hub-tag-container:hover > .hub-card-tag-tooltip-wrapper {
    display: block;
  }
  
  .hub-card-tag-label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 20px;
    color: #ffffff;
  }
  
  .hub-tag-body.green {
    background-color: #377b58;
    color: #19470a;
  }
  
  .hub-tag-body.blue {
    background-color: #3368d6;
    color: #ffffff;
  }
  
  .hub-tag-body.orange {
    background-color: #b15027;
    color: #593e0e;
  }
  
  .hub-tag-body.navy {
    background-color: #e1e5f5;
    color: #303c78;
  }
  
  .hub-tag-body.yellow {
    background-color: #edc748;
    color: #454208;
  }
  
  .hub-tag-body.teal {
    background-color: #4ba664;
    color: #ffffff;
  }
  
  .hub-tag-body.gray {
    background-color: #607180;
    color: #ffffff;
  }
  
  .hub-tag-body.lightblue {
    background-color: #f4f7fc;
    color: #4a7ccc;
  }
  
  .hub-tag-body.red {
    background-color: #dc3545;
    color: #ffffff;
  }
  .hub-tag-body.lime {
    background-color: #4d7c26;
    color: #ffffff;
  }
  
  .hub-tag-body.turquoise {
    background-color: #35788e;
    color: #ffffff;
  }
  
  .hub-tag-body.aqua {
    background-color: #3171b5;
    color: #ffffff;
  }
  
  .hub-tag-body.ultramarine {
    background-color: #565fe0;
    color: #ffffff;
  }
  
  .hub-tag-body.purple {
    background-color: #864dd6;
    color: #ffffff;
  }
  
  .hub-tag-body.pink {
    background-color: #bb328f;
    color: #ffffff;
  }
  