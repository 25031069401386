/* Prefix wb: Workbench */

.hub-wb-container {
  display: flex;
  gap: 30px;
  overflow: hidden;
}

.hub-wb-container.col {
  flex-direction: column;
}

.hub-wb-container.row {
  flex-direction: row;
  gap: 30px;
}

.hub-wb-container.stretch {
  flex: 1;
  overflow: auto;
}
