.project-pageSection {
  display: flex;
  flex-direction: column;
  background: var(--hub0, #fff);
}

.project-pageSectionHeader {
  display: flex;
  height: 44px;
  padding: 0px 20px 0px 15px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #edf1f8;
}

.project-pageSectionHeader > .icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--hub10, #f4f7fc);
}

.project-pageSectionHeader > .label {
  display: flex;
  align-items: center;
  color: var(--hub600, #1254c1);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.project-pageSectionBody {
  background-color: transparent;
  padding: 15px 50px 30px 50px;
  transition: all 0.3s;
  max-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
}
