.hub-ts-worklog {
    border-radius: 4px;
    border: 1px solid #E0E8F3;
    background: #FFF;
    width: 72px;
    height: 54px;
    box-sizing: border-box;
    padding: 8px 4px;
    display: flex;
    flex-direction: row;
}

.hub-ts-worklog.focused {
    border: 2px solid #1769F1;
}

.hub-ts-worklog-input { flex: 1;  }

.hub-ts-worklog-input > input {
    width: 100%;
    box-sizing: border-box;
    border: 0;
    height: 100%;
    outline: none;
    padding-left: 4px;

    color: #0C244C;

    text-align: left;
    font-family: Noto Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16.9px; /* 130% */

}

.hub-ts-worklog-icons { width: 16px; text-align: center;}
.hub-ts-worklog-icons > svg { color: #1769F1; cursor: pointer;}

.hub-ts-worklog-icons > .seperator { height: 1px; background-color: #E0E8F3; }


/* Disabled Config Set */

.hub-ts-worklog.disabled { background: #F5F7FA;}
.hub-ts-worklog.disabled .hub-ts-worklog-input > input { background: #F5F7FA;}
.hub-ts-worklog.disabled .hub-ts-worklog-icons > svg { color: #B5C1D1;}