.hub-pageSection {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border: 1px solid var(--hub100, #d2def2);
  background: var(--hub0, #fff);
}

.hub-pageSectionHeader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--hub100, #d2def2);
}

.hub-pageSectionHeader > .icon {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: var(--hub10, #f4f7fc);
}

.hub-pageSectionHeader > .label {
  display: flex;
  align-items: center;
  color: var(--hub600, #1254c1);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hub-pageSectionBody {
  background-color: transparent;
  padding: 15px 20px;
  transition: all 0.3s;
  max-height: 100vh;
}
