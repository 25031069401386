.hub-tabGroup-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.hub-tabGroup {
    height: 53px;
    display: flex;
    justify-content: flex-start;
    font-family: Open Sans,sans-serif;
    padding-left: 15px;
    border-bottom: 1px solid var(--hub100, #D2DEF2);

}

.hub-tabGroup .hub-tab {
    height: 54px;
    display: flex;
    flex-direction: column;
}

.hub-tabGroup .hub-tab:hover .body { color: #000000; }


.hub-tabGroup .hub-tab .body {
    font-style: normal;
    font-weight: var(--semibold);
    font-size: var(--size-md);
    text-decoration: none;
    color: #9DA9BD;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;
    cursor: pointer;
    flex: 1;
    line-height: 51px;
    transition: all .3s ease-out; 
}

.hub-tabGroup .hub-tab.active .body { 
    color: var(--hub500);
    cursor: default;
}


.hub-tabGroup .hub-tab .border {
    background-color: var(--hub100);
    height: 1px;
    display: block;
    border-radius: 12px;
}

.hub-tabGroup .hub-tab.active .border {
    background-color: var(--hub500);
    height: 3px;
    display: block;
}



.hub-tabContentGroup {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hub-tabContent {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hub-tab > .badge {
    box-sizing: border-box;
    height: 18px;
    min-width: 18px;
    text-align: center;
    line-height: 18px;

    border: 1px solid #FFFFFF;
    border-radius: 10px;

    display: inline-flex;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    place-content: center;
    -webkit-box-align: center;
    align-items: center;

    padding: 0 5px;

    margin-left: 6px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;

    z-index: 1;

    background-color: #4371D1;
    color: #FFFFFF;
    top: 0px;
    right: 0px;

}