.hub-thub-tline {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.hub-thub-tline-toolbar {
  height: 40px;
  padding: 4px;
  background-color: #ffffff;
  border-bottom: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tline-left-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tline-left-panel > .left-panel-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
.tline-right-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.tline-right-panel .date-info {
  line-height: 32px;
  margin-right: 16px;
  border-bottom: 1px dashed #1d5bbf;
  color: #1d5bbf;
  white-space: nowrap;
}

.hub-thub-tline-header {
  height: 44px;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: row;
}

.hub-thub-tline-body {
  flex: auto;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: row;
  overflow: auto;
}
.hub-thub-tline-left {
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}

.hub-thub-tline-left-header {
  text-align: left;
  line-height: inherit;
  padding-left: 8px;
  padding-right: 8px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  font-size: 14px;
  border-bottom: 2px solid #afbacc;
  background-color: #ffffff;
  line-height: 32px;
  box-sizing: border-box;
}

.hub-thub-tline-left-row {
  background-color: #ffffff;
  box-sizing: border-box;
  padding-left: 16px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hub-thub-tline-left-row.active {
  height: 100%;
  background-color: #2a80cf;
  color: rgb(255, 255, 255, 5);
}

.hub-thub-tline-right {
  flex: 1;
  overflow: auto;
}

/* 
    ROW 
    ROW Height: 30px
*/

.hub-thub-tline-row {
  height: 30px;
  border-bottom: 1px solid rgba(26, 26, 26, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;
}

.hub-thub-tline-footer {
  min-height: 34px;
  padding-left: 16px;
  border-top: 2px solid #afbacc;
}

.hub-thub-tline-body-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
}

.hub-thub-tline-wrapper {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.hub-thub-tline-item {
  border: 1px solid #84b1fa;
  border-radius: 8px;
  background-color: #ffffff;
  padding-left: 8px;
  box-sizing: border-box;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  color: var(--hub900, #051530);

  /* Noto Sans/11 Regular */
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 14.3px */
}

.hub-thub-tline-item.right {
  border-right: 3px solid #84b1fa;
  border-right-style: dotted;
}
.hub-thub-tline-item.left {
  border-left: 3px solid #84b1fa;
  border-left-style: dotted;
}

.hub-thub-tline-item.red {
  background-color: #fadfdc;
  border-color: #e65d4e;
}
.hub-thub-tline-item.pink {
  background-color: #f5dff1;
  border-color: #cf61bf;
}
.hub-thub-tline-item.green {
  background-color: #e9fff1;
  border-color: #89d6a4;
}
.hub-thub-tline-item.blue {
  background-color: #d5ebf5;
  border-color: #4288a6;
}
.hub-thub-tline-item.orange {
  background-color: #fce2b3;
  border-color: #cc8304;
}
.hub-thub-tline-item.navy {
  background-color: #e1e5f5;
  border-color: #6d7cc9;
}
.hub-thub-tline-item.yellow {
  background-color: #eef4ff;
  border-color: #a4bcfd;
}
.hub-thub-tline-item.teal {
  background-color: #daf2f0;
  border-color: #188f85;
}
.hub-thub-tline-item.gray {
  background-color: #ede6da;
  border-color: #968c7b;
}

.hub-thub-tline-item-name {
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  max-width: 240px;
  overflow: none;
  color: #637189;

  /* Noto Sans/11 Regular */
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%; /* 14.3px */
}

.hub-thub-tline-item .progress-bar {
  background-color: #84b1fa;
  height: 4px;
}
.hub-thub-tline-item.red .progress-bar {
  background-color: #e65d4e;
}
.hub-thub-tline-item.pink .progress-bar {
  background-color: #cf61bf;
}
.hub-thub-tline-item.green .progress-bar {
  background-color: #6dba88;
}
.hub-thub-tline-item.blue .progress-bar {
  background-color: #4288a6;
}
.hub-thub-tline-item.orange .progress-bar {
  background-color: #cc8304;
}
.hub-thub-tline-item.navy .progress-bar {
  background-color: #6d7cc9;
}
.hub-thub-tline-item.yellow .progress-bar {
  background-color: #8098f9;
}
.hub-thub-tline-item.teal .progress-bar {
  background-color: #188f85;
}
.hub-thub-tline-item.gray .progress-bar {
  background-color: #968c7b;
}

.hub-gantt-summary {
  .task-name {
    color: #000;
    /* Noto Sans/14 Semibold */
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 18.2px */
    padding-bottom: 5px;
  }
  .project-title {
    display: flex;
    align-items: center;
    gap: 5px;
    .project-name {
      color: #637189;
      /* Noto Sans/12 Regular */
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%; /* 15.6px */
    }
  }

  .summary-divider {
    width: 100%;
    background-color: #e8eff9;
    height: 1px;
    margin: 15px 0;
  }

  .summary-article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #071225;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
    padding-bottom: 8px;
    .summary-bold {
      font-weight: 600;
    }

    .summary-unit {
      color: #637189;
      font-weight: 500;
      margin-left: 2px;
    }
  }
}

.tline-resource-expand {
  svg {
    margin-left: 9px;
  }
}

.tline-resource-name {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 11px;
  padding-left: 16px;
}

.hub-thub-tline-marker-line {
  position: absolute;
  left: 36px;
  z-index: 999;
  width: 0px;
  height: 100%;
  border-left: 1px dashed #1769f1;
}

.hub-thub-tline-marker-line svg {
  position: absolute;
  top: 0px;
  left: -6px;
}

.hub-thub-tline-marker-mark {
  position: absolute;
  bottom: 1px;
  left: 36px;
  z-index: 10;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #1d5bbf;
  margin-left: -3px;
}
.hub-thub-tline-marker-mark:hover {
  bottom: 2px;
  opacity: 1;
  transform: scale(1.375);
  transition: 200ms all;
}

.table-divider {
  display: flex;
  width: 7px !important;
  height: 100%;
  border-right: 1px solid #d2def2;
  border-left: 1px solid #d2def2;
  background: #e8eff9;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.tline-resizer {
  display: flex;
  width: 7px !important;
  height: 100%;
  border-right: 1px solid #d2def2;
  border-left: 1px solid #d2def2;
  background: #e8eff9;
  z-index: 100;
  left: 0 !important;
}
