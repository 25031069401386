.skeleton {
  background: linear-gradient(
    90deg,
    #f4f7fc 0%,
    #e9edf4 25%,
    #f3f6fb 50%,
    #f4f7fc 75%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite ease-out;
}

@keyframes shimmer {
  0% {
    background-position: 100% 0;
    opacity: 1;
  }
  100% {
    background-position: -100% 0;
    opacity: 0.85;
  }
}
