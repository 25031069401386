

.card-progress{
    margin-left: 5px;
    display: inline-block;
    margin-top: 3px;
    width: 50px;
    height: 18px;
    position: relative;
    box-sizing: border-box;
}

.card-progress-label{
    position: absolute;
    width: 50px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    text-align: center;

    color: #000000;

}

.card-progress-bar{

    width: 100%;

    height: 18px;
    background: rgba(236, 230, 219, 0.4);
    border-radius: 4px;

}


.card-progress-bar-inline{
    width: 50px;
    height: 18px;
    background: #4371D1;
    border-radius: 5px;
}


.card-progress-label.red { color: #78180d; }
.card-progress-label.pink { color: #f5dff1; }
.card-progress-label.green { color: #19470a; }
.card-progress-label.blue { color: #214352; }
.card-progress-label.orange { color: #593e0e; }
.card-progress-label.navy { color: #303c78; }
.card-progress-label.yellow { color: #454208; }
.card-progress-label.teal { color: #084a44; }
.card-progress-label.gray { color: #42403c; }


.card-progress-bar-inline.red { background: #fadfdc; }
.card-progress-bar-inline.pink { background: #69215e; }
.card-progress-bar-inline.green { background: #d2f0c7; }
.card-progress-bar-inline.blue { background: #d5ebf5; }
.card-progress-bar-inline.orange { background: #fce2b3; }
.card-progress-bar-inline.navy { background: #e1e5f5; }
.card-progress-bar-inline.yellow { background: #edea85; }
.card-progress-bar-inline.teal { background: #daf2f0; }
.card-progress-bar-inline.gray { background: #ede6da; }






