.hub-widget-grid-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.hub-widget-grid {
  flex: 1;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.hub-widget-grid table {
}
.hub-widget-grid-actionBar {
  position: absolute;
  right: 0px;
  top: -57px;
}

.hub-widget-grid-filterBar {
  border-bottom: 1px solid #ebebeb;
  padding: 0 16px;
  min-height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.hub-widget-grid-toolBar {
  border-bottom: 1px solid #ebebeb;
  padding: 0 16px;
  height: 56px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.hub-widget-grid-tableArea {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: 450px;
}

.hub-widget-grid-tableArea-buttonArea {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
}

.hub-widget-grid-tableArea-actionBar {
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hub-widget-grid-tableArea-actionBar .selectedRows {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 600;
  line-height: 24px;
  margin: 0 8px;
  font-family: Open Sans, sans-serif;
  font-size: 1rem;
}

.hub-widget-grid-tableArea-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
}
.hub-widget-grid-pagination {
  /*  height: 36px;
    line-height: 36px; */

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 16px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #212121;
}

.hub-widget-grid-pagination-paging {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.hub-widget-grid-pagination-paging .left-section {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  gap: 7px;
}

.hub-widget-grid-pagination-paging .per-page {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background-color: #ffffff;
  height: 36px;
  box-sizing: border-box;
  padding-left: 14px;
  width: 68px;
  margin-left: 7px;
}

.hub-widget-grid-pagination-paging .per-page select {
  height: 19px;
  width: auto;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background-color: #ffffff;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #212121;
}

.hub-widget-grid-pagination-paging .per-page option {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;

  color: #212121;
}

/* TH Styles */

.hub-widget-grid th {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;

  text-align: left;
  padding-left: 16px;
  width: auto;
  border-bottom: 1px solid #d1d2d5;
  
  background: var(--hub10, #F4F7FC);
  box-shadow: -1px 0px 0px 0px #D2DEF2 inset, 0px -1px 0px 0px #D2DEF2 inset;
  box-sizing: border-box;
}

.hub-widget-grid th span {
  padding-right: 8px;
  box-sizing: border-box;
}

.hub-widget-grid .th {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;

  text-align: left;

  white-space: nowrap;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #000000;

  height: 33px;
  line-height: 33px;


}

.hub-widget-grid .th-resize-handler {
  border: 0;
  border-right: 1px solid #D2DEF2;
  width: 8px !important;
  right: -8px !important;
}

.hub-widget-grid thead {
  background-color: #f5f7fa;
  height: 32px;
}

.hub-widget-grid thead > th:last-child > .dtThRb {
  display: none;
}

.hub-widget-grid-picker {
  width: 24px;
  padding-left: 8px;
}

.hub-widget-grid-picker > input {
  border-color: rgba(0, 0, 0, 0.6);
  width: 16px;
  height: 16px;
  display: block;
  margin: 0;
}

/* TR Styles */

.hub-widget-grid tr.active {
  background-color: #eef7ff !important;
}

/* TD Styles */

.hub-widget-grid td {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 33px;
  /* identical to box height */

  color: #000000;

  text-align: left;
  padding-left: 16px;
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  /*white-space: nowrap;*/
  height: 33px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hub-widget-grid td .hoverIcon {
  display: none;
  background-color: #ffffff;
}
.hub-widget-grid tr.active td:hover .hoverIcon {
  background-color: #eef7ff;
}
.hub-widget-grid td:hover .hoverIcon {
  display: inline-block;
  padding: 0px 4px;
}

.hub-widget-grid td .hoverIcon.active {
  display: inline-block;
  padding: 0px 4px;
}

.hub-widget-grid td a {
  /* UI / UI Text 14 Reg */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  /* identical to box height, or 171% */

  letter-spacing: -0.006em;

  /* Primary / PR600 */

  color: #0e73f6;
  text-decoration: none;

  cursor: pointer;

  border-bottom: 1px solid #d9eafd;
}

.hub-widget-grid td.text {
  text-align: left;
}

.hub-widget-grid td.date {
  text-align: left;
}

.hub-widget-grid td.number {
  text-align: left;
}
