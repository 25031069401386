.skeleton-table-container {
  max-width: 100%;
  height: 100%;
  border: 1px solid #d2def2;
  overflow: auto;

  .skeleton-table-wrapper {
    width: fit-content;
    position: relative;
    .skeleton-th-row {
      height: 44px;
      display: flex;
      position: sticky;
      top: 0;
      z-index: 2;
      color: #061226;
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 200% */
      .skeleton-th {
        box-sizing: border-box;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 11px 1px 10px;
        background: #e8eff9;
        box-shadow: -1px 0px 0px 0px #d2def2 inset,
          0px -1px 0px 0px #d2def2 inset;
        overflow: hidden;
        white-space: nowrap;
        .skeleton-th-resizer {
          right: -11px !important;
          box-shadow: -1px 0px 0px 0px #d2def2 inset;
        }
      }
    }

    .skeleton-td-row {
      height: 44px;
      display: flex;
      box-sizing: border-box;
      transition: all 230ms ease-in-out;
      color: var(--hub900, #051530);
      font-family: "Noto Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 184.615% */
      .skeleton-td {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0px 10px;
        border-right: 1px solid rgba(14, 63, 145, 0.1);
        border-bottom: 1px solid rgba(14, 63, 145, 0.1);
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
