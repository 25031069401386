
.hub-modalWrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.hub-modal {
  /*  width: 80%;
    height: 60%;

    */
    background: #FFFFFF;
    margin: 100px auto;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
    display: flex;
    flex-direction: column;
}  

.hub-modalHeader {
    background-color: #f5f7fa;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px;
    justify-content: space-between;
}

.hub-modalHeader-title {
    font-weight: 600;
    margin-top: 4px;
}

.hub-modalHeader-title > .icon {
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: inline-block;
    text-align: center;
    border:1px solid #000000;
    margin-right: 4px;
}

.hub-modalBody {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex:1;
}

.hub-modalBody-button-area {
  
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    margin-bottom: 10px;
    margin-top: auto;
}
