.input-group-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  .input-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    row-gap: 22px;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
}
