.columns {
  background-color: #f5f7fa;
}

.board-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;
}

.board-page > .board-area {
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
}

.board {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;

  flex: 1;
  gap: 2px;
  height: 100%;

  background-color: #f5f7fa;
}

.board > .column {
  width: 340px;
  min-width: 340px;
  box-sizing: border-box;
  border-right: 1px solid #e1e6ef;
  display: flex;
  flex-direction: column;
}
.board > .column:hover {
  background-color: #eceff3;
}

.board > .column > .header {
  height: 60px;

  font-size: 16px;
  display: flex;

  padding: 0px 19px 0px 20px;
}

.board > .column > .column-button-bar {
  padding: 0px 19px 15px 20px;
}
.column > .header > .column-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.column > .header > .column-name {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 60px;
  /* identical to box height, or 400% */

  color: #0c244c;
}

.column > .header > .column-name > .toolbar-number {
  display: inline-block;

  min-width: 22px;
  height: 22px;
  background: #fbfcfd;
  border-radius: 6px;
  line-height: 22px;
  margin-left: 15px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */
  border-radius: 6px;
  background: rgba(12, 36, 76, 0.05);

  text-align: center;

  color: #0c244c;
}

.column > .header > .column-description {
  margin-top: 5px;
  padding-top: 5px;
  margin-bottom: 5px;
  border-top: 1px solid rgb(229, 235, 238);
  height: 150px;
}

.column > .header > .column-toolbar > .toolbar-numbers {
  color: #19470a;
}

.column > .header > .column-toolbar > .toolbar-collapse {
  cursor: pointer;
}

.column > .header > .column-toolbar > .toolbar-numbers.red {
  background-color: rgb(244, 68, 76);
}

.board > .column > .card-deck {
  min-height: 100px;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden auto;
  padding: 2px 19px 0px 20px;
}

.hub-board-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto hidden;
  scroll-behavior: smooth;
}

.board > .column.collapsed {
  background-color: #efefef;
  border-radius: 8px;
  width: 80px;
  min-width: 80px;
}

.column.collapsed > .header > .column-toolbar {
  flex-direction: column;
  padding-left: 5px;
}

.board > .column.collapsed > .header {
  border: none;
  flex-direction: column;
}

.column.collapsed > .header > .column-description {
  display: none;
}

.column.collapsed > .header > .column-name {
  transform: rotate(-90deg);
  margin-top: 120px;
  word-break: keep-all;
}
.board > .column.collapsed > .header > .column-toolbar > .toolbar-number {
  /*
    background-color: #d2f0c7;
    padding-left: 2px;
    padding-right: 2px;
    border-radius: 4px;
    border: 1px solid #6bc84c;
    */
  color: #19470a;
  font-weight: bold;
  margin-top: 2px;
}

.board > .column.collapsed > .card-deck {
  display: none;
}
