.hub-cardToolbarIcon {
  min-width: 30px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.hub-cardToolbarIcon .icon {
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hub-cardToolbarIcon .label {
  padding-right: 10px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  /* identical to box height, or 214% */

  text-align: left;

  color: #174999;
}

.label.active {
  color: #ffffff;
}

.hub-cardToolbarIcon.active {
  background: #f4f7fc;
  border: 1px solid #1d5bbf;
}

.hub-cardToolbarIcon.favorite.active {
  background: #fff3d3;
  border: none;
}

.hub-cardToolbarIcon.favorite.active svg {
  color: #ffb800;
}

.hub-cardToolbarIcon:hover,
.hub-cardToolbarIcon.hovered {
  background: #f4f7fc;
}

.hub-cardToolbarIcon .icon svg {
  color: #bdc9dc;
}

.hub-cardToolbarIcon .icon.favorite.active svg {
  color: #ffb800;
}

.hub-cardToolbarIcon:hover .icon svg,
.hub-cardToolbarIcon.active .icon svg,
.hub-cardToolbarIcon.hovered .icon svg {
  color: #1d5bbf;
}

/* block */

.hub-cardToolbarIcon.blockIcon > .icon {
}

.hub-cardToolbarIcon.blockIcon.hovered {
  background-color: #fdeff2;
  border: 0;
}

.hub-cardToolbarIcon.blockIcon.hovered svg {
  color: #ea2246;
}

.hub-cardToolbarIcon.blockIcon.selected {
  background-color: #ea2246;
  border: 0;
}

.hub-cardToolbarIcon.blockIcon.selected svg {
  color: #ffffff;
}

/* blocked active ve selected */

.hub-cardToolbarIcon.blockIcon.blocked.active {
  background-color: #fdeff2;
  border: 0;
}
.hub-cardToolbarIcon.blockIcon.blocked.active svg {
  color: #ea2246;
}

.hub-cardToolbarIcon.blockIcon.blocked.selected {
  background-color: #ea2246;
  border: 0;
}
.hub-cardToolbarIcon.blockIcon.blocked.selected svg {
  color: #ffffff;
}

/* resolved active ve selected */

.hub-cardToolbarIcon.blockIcon.resolved.active {
  background-color: #e8ffee;
  border: 0;
}
.hub-cardToolbarIcon.blockIcon.resolved.active svg {
  color: #2ed65e;
}

.hub-cardToolbarIcon.blockIcon.resolved.selected {
  background-color: #2ed65e;
  border: 0;
}
.hub-cardToolbarIcon.blockIcon.resolved.selected svg {
  color: #ffffff;
}

/* todo */

.hub-cardToolbarIcon.todo.selected {
  background-color: #1d5bbf;
  border: 0;
}
.hub-cardToolbarIcon.todo.selected svg {
  color: #ffffff;
}

.hub-cardToolbarIcon.todo.active {
  background-color: #f4f7fc;
  border: 0;
}
.hub-cardToolbarIcon.todo.active svg {
  color: #174999;
}
