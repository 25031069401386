.hud-dt-th-row {
    height: 44px;
    display: flex;
    position: sticky;
    top: 0;
    z-index: 2;
    color: #061226;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 200% */
    .check-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      background: var(--hub25, #f4f7fc);
      box-shadow: 0px -1px 0px 0px #d2def2 inset;
      padding: 0px 10px;
    }
  }
  