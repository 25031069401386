.hub-thub-toolbar {
    display: flex;    
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    border-bottom: 3px solid #f0f0f0 ;
    padding: 8px 16px 8px 16px;
}

.hub-thub-toolbar .controlPanel {
    display: flex;    
    flex-direction: row;
}
.hub-thub-toolbar .teamPicker {
   line-height: 32px;
   flex: 1;
}

.hub-thub-body {
    flex: 1;
    overflow: auto;
}


.hub-thub-toolbar .controlPanel .date-range {
    line-height: 32px;
    margin-right: 16px;
    border-bottom: 1px dashed #1d5bbf;
    color: #1d5bbf;
    white-space: nowrap;
    cursor: pointer;
}

.hubTeam-date-info{
    margin-top: -35px;
    width: 90%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
        
}
.hubTeam-date-info span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    
}


.hubTeam-line{
    height: 1px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0;
    margin-bottom: 16px;
}