.hud-dt-th-row {
  height: 44px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  color: #061226;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
}
