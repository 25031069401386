.hub-page-header {
  position: relative;
  height: 53px;
  border-bottom: 1px solid var(--hub100);
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.hub-page-header-title-wrapper {
  display: flex;
  flex-direction: row;
}

.hub-page-header .center {
}

.hub-page-header .title {
  position: absolute;
  left: 0;
  font-size: var(--size-xlg);
  color: #061226;
  font-weight: var(--semibold);
  line-height: 53px;
  margin-left: 14px;
  display: flex;
  flex-direction: row;
}

.hub-page-header-title-icon {
  margin-right: 15px;
}

.hub-page-header .right {
  position: absolute;
  right: 0;
  height: 100%;
  margin-right: 10px;
  display: flex;
  align-items: center;
}
