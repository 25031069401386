.hud-dt-td-row {
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  transition: all 230ms ease-in-out;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 184.615% */
  background-color: #fff;
  .check-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(14, 63, 145, 0.1);
    padding: 0px 10px;
  }

  .hier-space {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
    width: 30px;
    border-bottom: 1px solid rgba(14, 63, 145, 0.1);
  }

  &.selected {
    background: var(--hub50, #e8eff9);
    box-shadow: 0px -1px 0px 0px #1769f1 inset, 0px 1px 0px 0px #1769f1 inset;
    color: var(--hub500, #1769f1);
  }

  &.hovered {
    background: var(--hub25, #f4f7fc);
  }
}
