
.hub-timesheetSideSummary-table th {
    background-color: #f5f7fa;
    font-size: 1.1em;
    text-align: left;
    border: 1px solid #ddd;
    padding: 8px;
    line-height: 1.42857143;
    font-weight: normal;
}

.hub-timesheetSideSummary-table td {
    font-size: 1.1em;
    border: 1px solid #ddd;
    border-top: 0;
    padding: 8px;
    line-height: 1.42857143;
    font-weight: normal;
}

.hub-timesheetSideSummary-table td span.project, span.task, span.activity {
    display: block;
}

.hub-timesheetSideSummary-table td span.project {
    font-size: 12px;
}

.hub-timesheetSideSummary-table td span.task {
    font-weight: bold;
    font-size: 16px;
}

.hub-timesheetSideSummary-table td span.activity {
    font-size: 14px;
}

.hub-timesheetSideSummary-h2 {
    font-size: 20px;
    font-family: 'Open Sans';
    font-weight: 500;
}