.hub-columnPicker-wrapper {
    position: absolute;
    width: 320px;
    margin-left: -98px;
    z-index: 1090;
    margin-top: 6px;

}

.hub-columnPicker-wrapper .arrow {
    transform: rotate(135deg);
    background-color: #ffffff;
    border: none;
    box-shadow: rgb(0 0 0 / 20%) -1px 1px 2px;
    height: 14px;
    width: 14px;
    display: block;
    margin-left: 150px;
    z-index: 1091;

}

.hub-columnPicker{
    background: #FFFFFF;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    border-color: rgba(0,0,0,.15);
    margin-top:-8px;
    z-index: 1092;

}

.hub-columnPicker .title {
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hub-columnPicker .search {
    width: 100%;
    height: 32px;
    border-radius: 6px;
    background-color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
}

.hub-columnPicker .search input {
    padding-left: 32px;
    height: 32px;
    font-size: 14px;
    width: 100%;
    border-radius: 6px;
    border: solid 1px #c8d1e0;
    box-sizing: border-box;
    font-family: Open Sans;
}

.hub-columnPicker .inner {
    height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
}

.hub-columnPicker .inner .title{
    font-size: 14px;
    text-transform: uppercase;
    padding-top: 8px;
}


.hub-columnPicker .inner .column {
    padding: 6px 12px 6px 18px;
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: normal;

}

.hub-columnPicker .inner .column input {
    border-color: rgba(0, 0, 0, 0.6);
    width: 16px;
    height: 16px;
    display: block;
    margin: 0;
    cursor: pointer;
}

.hub-columnPicker .inner .column:hover {
    background-color: #f5f7fa;
} 

.hub-columnPicker .buttonBar {
    padding-top: 8px;
}