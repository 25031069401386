.hub-teamHub-picker {
  background: #ffffff;
  border: 1px solid rgba(74, 124, 204, 0.2);
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 36px;
  padding: 4px;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.hub-teamHub-picker .label {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: max-content;
}

.hub-teamHub-picker .label .hub-icon {
  height: 100%;
}

.hub-teamHub-picker .menu {
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 3px;
  background: var(--hub25, #f4f7fc);
  padding: 0px 5px 0px 10px;
  color: #1769f1;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  height: 100%;
}

.picker-options {
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.option-item {
  display: flex;
  height: 38px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  border-radius: 3px;
}

.option-item.selected {
  border-radius: 3px;
  background: var(--hub500, #1769f1);
  color: #fff;
}

.option-item:not(.selected):hover {
  cursor: pointer;
  background: var(--hub25, #f4f7fc);
}
