/* New Button Theme */

.hub-button-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.hub-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: var(--hub500, #1769f1);
  color: #fff;
  box-sizing: border-box;
  box-shadow: none;
  height: 34px;
  width: max-content;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px; /* 138.462% */
  cursor: pointer;
  transition: all 230ms ease-in-out;
  padding: 0px 6px;
  border-radius: 6px;
  .label {
    padding: 0px 5px;
  }
}

.hub-button.primary {
  background: var(--hub500, #1769f1);
  color: var(--white, #fff);
  .icon {
    svg {
      path {
        stroke: var(--white, #fff);
      }
    }
  }
}

.hub-button.primary:hover {
  border: var(--theme-light-600, #1254c1);
  background: var(--theme-light-600, #1254c1);
}

.hub-button.secondary {
  border: 1px solid var(--hub50, #e8eff9);
  background: var(--white, #fff);
  color: var(--hub700, #0e3f91);
  .icon {
    svg {
      path {
        stroke: #0e3f91;
      }
    }
  }
}
.hub-button.secondary.black {
  border: 1px solid var(--hub50, #e8eff9);
  background: var(--white, #fff);
  color: var(--hub900, #051530);
  .icon {
    svg {
      path {
        stroke: var(--hub900, #051530);
      }
    }
  }
}

.hub-button.secondary:hover {
  border: 1px solid #f4f7fc;
  background: #f4f7fc;
}

.hub-button.alert {
  background: #f43f5e;
  color: var(--white, #fff);
  .icon {
    svg {
      path {
        stroke: var(--white, #fff);
      }
    }
  }
}

.hub-button:disabled {
  background: #f4f7fc !important;
  cursor: not-allowed;
  color: var(--hub200, #a5bde5) !important;

  .label {
    color: var(--hub200, #a5bde5) !important;
  }

  .icon {
    svg {
      path {
        stroke: var(--hub200, #a5bde5);
      }
    }
  }
}

/* ----------------------------------- */

.hub-button-wrapper .superscript {
  position: absolute;
  right: 4px;
  top: -8px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 18px;
  border-radius: 9px;
  font-size: 11px;
  font-weight: bold;
  background-color: #1c5bbf;
  color: #ffffff;
}

.toolbar-filters-button {
  padding: 0 !important;
  background: #ffffff !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #051530 !important;
  border: 1px solid rgba(74, 124, 204, 0.2) !important;
  border-radius: 6px !important;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05) !important;
  display: flex !important;
  min-width: 78px !important;
  align-items: center !important;
  justify-content: center !important;
  height: 34px !important;
}

.hub-button.button-2 {
  background: #ffffff;
  color: #1d5bbf;
  border: 1px solid #dddddd;
  border-radius: 4px;
  padding: 0px 8px;
  box-sizing: border-box;
  box-shadow: none;
  height: 32px;
  display: inline-block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  line-height: 1;
  font-style: normal;
  cursor: pointer;
  overflow: hidden;
}

.hub-button.button-2.active {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-2:hover {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-2 > .icon > svg {
  color: #1d5bbf;
}

/* Icon Button */
.hub-button.icon-button {
  background: #3272d9;
  border: 1px solid #dddddd;
  border-radius: 4px;

  box-shadow: none;
  display: inline-block;

  font-size: 18px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  color: #ffffff;

  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 0px 7px;
  box-sizing: border-box;
}

.hub-button.icon-button > .icon {
  margin: 0;
}

.hub-button.icon-button:hover {
  background: #5691f0;
  border-color: #ffffff;
}

.hub-button.icon-button > .icon > svg {
  color: #ffffff;
}

/* Icon Button */

.hub-button.icon-button-2 {
  background: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;

  box-shadow: none;
  display: inline-block;

  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  font-style: normal;
  color: #1d5bbf;

  cursor: pointer;
  height: 40px;
  width: 40px;
  padding: 0px 7px;
  box-sizing: border-box;
}

.hub-button.icon-button-2 > .icon {
  margin: 0;
}

.hub-button.icon-button-2:hover {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.icon-button-2 > .icon > svg {
  color: #1d5bbf;
}

.hub-button.icon-button-2.active {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

/* Küçük buton, select all, deselect all için */

.hub-button.tiny-text {
  color: #1d5bbf;
  height: 32px;
  font-size: 12px;
  line-height: 24px;
  background: #ffffff;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.hub-button.tiny-text .icon {
  font-size: 14px;
}

.hub-button.tiny-text:hover {
  background: rgba(0, 0, 0, 0.05);
}

.hub-button.tiny-text.black {
  color: rgba(5, 21, 48, 1);
  background: unset;
  font-family: Noto Sans;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
}
.hub-button.tiny-text.black:hover {
  background: linear-gradient(0deg, #f2f4f6, #f2f4f6),
    linear-gradient(0deg, #f2f4f7, #f2f4f7);
}

.hub-button.tiny-text.black .icon {
  margin-right: 5px !important;
}

.hub-button.tiny-icon {
  color: #051530;
  height: 24px;
  font-size: 11px;
  line-height: 24px;
  background: #ffffff !important;
  padding: 0px 8px;
}

.hub-button.tiny-icon:hover {
  background: rgba(0, 0, 0, 0.05);
}

.hub-button.tiny-icon:disabled:hover {
  background: transparent;
}

/* button-3 */

.hub-button.button-3 {
  background: #ffffff;
  color: #1d5bbf;
  border: 0px solid #dddddd;
  border-radius: 4px;
  padding: 0px 8px;
  box-sizing: border-box;
  box-shadow: none;
  height: 32px;
  display: inline-block;
  font-size: 14px;
  line-height: 32px;
  font-weight: 600;
  line-height: 1;
  font-style: normal;
  cursor: pointer;
}

.hub-button.button-3.active {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-3:hover {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-3 > .icon > svg {
  color: #1d5bbf;
}

.hub-button.icon-button-2.colored.active .icon > svg {
  background: linear-gradient(90deg, #fdbb2d 0%, #22c1c3 100%);
  border-radius: 50%;
  color: transparent;
}

/* button-4 New Button Style */

.hub-button.button-4 {
  background: #ffffff;
  color: #1d5bbf;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  padding: 5px 10px 6px 10px;
  box-sizing: border-box;
  box-shadow: none;
  height: 40px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.hub-button.button-4 .icon {
  margin-right: 10px;
  padding-top: 2px;
}

.hub-button.button-4 .label {
  font-size: 13px;
  font-weight: 600;
}

.hub-button.button-4.active {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-4:hover {
  background: rgba(0, 0, 0, 0.05);
  border-color: #5691f0;
}

.hub-button.button-4 > .icon > svg {
  height: 16px;
  color: #1d5bbf;
}

/* button-5 Dashboard Options Button White, on active blue */

.hub-button.button-5 {
  width: 24px;
  height: 24px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 0;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.hub-button.button-5 .label {
  font-size: 13px;
  font-weight: 600;
}

.hub-button.button-5.active {
  background: #1254c1;
}

.hub-button.button-5:hover {
  border: 1px solid #e8eff9;
}

.hub-button.button-5:hover > .icon > svg {
  color: #779dd9;
}

.hub-button.button-5 > .icon > svg {
  height: 16px;
  color: #d2def2;
}

/* New Main Button */

.new-main-button:disabled {
  background: #cad4e4;
  color: #ffffff !important;
  border-color: transparent;
  cursor: not-allowed;
}

.new-board-add-button {
  border-radius: 12px;
  background: var(--theme-light-500, #1769f1);
  box-shadow: 0px 5px 10px 0px rgba(29, 91, 191, 0.2);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 300ms ease-in-out;
}

.new-board-add-button .icon {
  position: absolute;
  left: 10px;
  display: flex;
  width: 30px;
  height: 30px;
  flex-direction: column;
  justify-content: center;
}

.new-board-add-button .label {
  color: #fff;
  font-family: "Open Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 133.333% */
}

.hub-button.new-plain-button {
  color: #1d5bbf;
  height: 30px;
  font-size: 12px;
  line-height: 30px;
  background: #ffffff;
  padding: 0px 10px;

  display: flex;
  align-items: center;
}

.hub-button.new-plain-button .label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;

  color: #1d5bbf;
}

.hub-button.new-plain-button .icon {
  padding: 0;
  margin-right: 8px;
}

.hub-button.new-plain-button .icon svg {
  color: #bdc9dc;
}

.hub-button.new-plain-button:disabled:hover {
  background: transparent;
}

/* SA AS  NEW HUB Buttons */
.hub-button.new-icon-button {
  border: 1px solid rgba(74, 124, 204, 0.2);
  border-radius: 6px;
  background-color: #ffffff;
  color: #0c244c;
  height: 34px;
  padding: 0px 8px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.hub-button.new-icon-button .icon {
  line-height: 32px;
  margin-right: 0px;
}

.hub-button.new-icon-button.colored.active .icon > svg {
  background: linear-gradient(90deg, #fdbb2d 0%, #22c1c3 100%);
  border-radius: 50%;
  color: transparent;
}

.hub-button.new-toolbar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0 !important;
  border-radius: 6px;
  color: #0c244c;
  height: 34px;
  padding: 0px 9px;
  border: 1px solid rgba(74, 124, 204, 0.2);
  background: var(--theme-light-0, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.hub-button.new-toolbar-button .icon {
  line-height: 32px;
  margin-right: 5px;
}

.hub-button.new-toolbar-button .label {
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #051530;
}

.hub-button.timesheet-add-button {
  border: 0px solid var(--hub100);
  border-radius: 6px;
  background-color: #ffffff;
  color: #b5c1d1;
  height: 34px;
  padding: 0px 10px;
}
.hub-button.timesheet-add-button .icon {
  line-height: 32px;
  margin-right: 8px;
}
.hub-button.timesheet-add-button .label {
  color: #b5c1d1;
  text-align: center;
  font-family: Noto Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 16.9px; /* 130% */
}
