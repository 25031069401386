.copy-task-body{
    /**/padding: 12px 22px 12px 22px;
   display: flex;
   flex-direction: column;
   flex: 1;
}

.copy-task-body > span{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
       
    color: #000000;
}

.copy-task-list{
    padding-top: 5px;
    overflow: auto;
    max-height: 150px;
    flex: 1;
}

.copy-task-line{
    border-top:2px solid;
}
.copy-task-body-area{
    flex: 1;
}

.copy-task-button-area{

    width: 100%;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: end;
   
   
}
