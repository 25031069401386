

/* Block */ 


.hub-teamHub-card .block {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #faf0f0;
    border-radius: 5px;
}

.hub-teamHub-card .block.resolved{
    margin-bottom: 0;
    background-color: #F5F9FF;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
   
}

.hub-teamHub-card .card-section-body .hub-inputControl .label {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0C244C;
}

.hub-teamHub-card .block > span {
    padding-left: 5px;
    color:red;
    font-weight: bold;
    margin-bottom: 2px;
}

.block-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.block-reason{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #EA2246;
}

.block-reason.resolved{
    color: #0C244C;
}

.block-date{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #0C244C;
    opacity: 0.4;

}

.block-owner{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    /* identical to box height */


    color: #0C244C;

    opacity: 0.5;
}

.block-description{
    margin-top: 5px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #0C244C;
}

.block-footer {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding-left: 5px;
    padding-top: 10px;
    

    color: rgba(0, 0, 0, 0.5);
}

.block-line{
    width: 250px;
    height: 1px;

    background: #0C244C;
    opacity: 0.1;
    margin-left: 10px;
}

.hub-teamHub-card .resolve {


    padding: 10px;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #F5F9FF;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

}



.block-resolve-reason{
    
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #2ED65E;
}

.hub-teamHub-card .resolve>span {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;

    color: #2ED65E;

}


.card>.body>.block>input {
    padding-left: 5px;
    background-color: #faf0f0;
    border: none !important;
    border-color: transparent;
    outline: none;
}


.block-button-bar {
    display: flex;
    flex-direction: row;
}

/* Blocking End */