.to-do {
    display: flex;
    flex-direction: column;
}

.to-do>.toolbar {
    display: flex;
    flex-direction: row;
    gap:10px;
    align-items: center;
}
.to-do>.toolbar>.checklist{
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.to-do>.toolbar>.ratio {
    /*margin-top: 5px;*/
    background-color: rgb(39, 179, 39);
    border-radius: 5px;
    padding: 1px 4px 1px 4px;
    color: aliceblue;
    font-size: 10px;
}


.to-do>.toolbar>.new-todo-button{
    cursor: pointer;
}

.new-to-do{
    display: flex;
    flex-direction: column;

   width:235px
}

.new-to-do>.buttons{
    margin-top: -10px;
    display: flex;
    flex-direction: row;
}

.new-to-do>textarea {
   
    border: solid 1px blue;
    border-radius: 3px;

}


.to-do >.list {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
   
}


.to-do>.list>.items {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    word-break  : break-word;
    overflow: hidden;
   /* border-top: 1px solid #F4F7FC;*/
    padding-top: 5px;
    padding-bottom: 5px;
    font-family: 'Open Sans';

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* or 133% */


    color: #0C244C;

}

.card-to-do-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
   
    
}

.new-to-do-description-wrapper{
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    border-bottom: 1px solid  rgba(232, 239, 249, 1);
}