.spinner2 {
  animation: spin1 infinite 3s linear;
}

.spinner-reversed {
  animation: spin-reverse infinite 1.5s linear;
}
.spinner2.fast {
  animation: spin1 infinite 0.5s linear;
}

@keyframes spin-reverse {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spin1 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
