.hub-dataTable-filterSelector {
    width: 200px;
    padding: 5px 8px;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 4px;
    background: #fff;
    transition: all .2s;
}

.hub-dataTable-filterSelector:hover {
    border-color: #5691f0;
}

.hub-dataTable-filterSelector.active {
    box-shadow: 0 0 0 1px #fff, 0 0 0 2px #1d5bbf;
}

.hub-dataTable-filterSelector .select input {
    width: calc(100% - 16px);
    border: none;
    box-shadow: none;
    outline: none;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    color: rgba(0,0,0,.87);
    padding: 0;
    background-color: transparent;
    transition: all .2s;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.hub-dataTable-filterSelector .select input:focus {
    outline: none;
}


.hub-dataTable-filterSelector .list {
    position: absolute;
    background: #FFFFFF;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    border-color: rgba(0,0,0,.15);
    z-index: 1092;    
    width: 216px;
    box-sizing: border-box;
    margin-left: -8px;
    margin-top: 6px;
    height: 200px;
    overflow-y: auto ;
}

.hub-dataTable-filterSelector .list .attr {
    font-size:14px;
    line-height: 26px;
    cursor: pointer;
}

.hub-dataTable-filterSelector .list .attr:Hover {
    background-color: #f5f7fa;
  }