/*
    prefix: do for data operations
*/

.widget-wrapper {
  width: 100%;
  height: 100%;
  /*  padding: 25px 0px 31px 0px; */
  display: flex;
  padding-top: 10%;
  justify-content: center;
  box-sizing: border-box;
}

.hub-do-gauge-chart-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.score {
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
}

.mid-number {
  left: 50%;
  top: -15%;
  transform: translate(-50%, 0);
}

.start-number {
  bottom: 35%;
}

.end-number {
  bottom: 35%;
}

.chart-container {
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  max-width: 100%;
  height: 100%;
  position: relative;
}

.chart-container > div {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  scale: 1.5;
}

.bar > div {
  scale: 1;
}

.hub-do-gauge-chart {
  min-width: 140px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #666666;
}

.gauge-treshold-list {
  display: flex;
  flex-direction: column;
}
