.hub-widget {
  border-radius: 12px;
  border: 1px solid var(--hub50, #e8eff9);
  background: #fff;
  box-shadow: 0px 1px 2px 0px rgba(6, 18, 38, 0.02);
  padding: 15px 15px 10px 15px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  height: -webkit-fill-available;
}

.hub-widget-header {
  display: flex;
  flex-direction: row;
  height: 39px;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

.hub-widget-header-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.hub-widget-header > .hub-widget-header-title-wrapper > .title {
  color: #000;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 160% */
}

.hub-widget-header > .hub-widget-header-title-wrapper > .info-icon {
  /* position: relative; */
  cursor: pointer;
}

.hub-widget-header > .hub-widget-header-title-wrapper > .title-icon {
  margin-right: 13px;
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #d8dfee;
  text-align: center;
}

.hub-widget-header > .hub-widget-header-title-wrapper > .title-icon > svg {
  display: block;
  margin-top: 5px;
  margin-left: 5px;
  color: #2f5ab9;
}

.hub-widget > .body {
  flex: 1;
}

.hub-widget-options-wrapper {
  position: relative;
}

.hub-widget-options-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px 0px;

  position: absolute;
  width: 160px;
  height: 120px;
  left: -137px;
  top: 30px;
  z-index: 50;
  background: #ffffff;
  border: 1px solid #d2def2;
  box-shadow: 0px 20px 30px rgba(18, 84, 193, 0.25);
  border-radius: 6px;
}

.hub-widget-options-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  width: 160px;
  height: 40px;
}

.hub-widget-options-menu-item:hover {
  background-color: #e8eff9;
  width: 160px;
  cursor: pointer;
}

.hub-widget-options-menu-item > span {
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.hub-widget-options-menu-item > svg {
  margin-left: 10px;
  margin-right: 10px;
}

.widget-menu-divider {
  width: 160px;
  height: 2px;
  background: #f4f7fc;
}

.hub-widget-info-tooltip {
  padding: 8px 10px;
  position: absolute;
  width: 140px;
  max-width: 190px;
  height: auto;
  backdrop-filter: blur(50px);
  background: rgba(18, 84, 193, 0.1);
  border: 1px solid #1254c1;
  box-shadow: 0px 20px 30px rgba(18, 84, 193, 0.11);
  border-radius: 6px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #092a60;
  z-index: 20;
  right: 0px;
  top: 31px;
}

.hub-widget-info-tooltip-centered {
  padding: 8px 10px;
  position: absolute;
  max-width: 100%;
  height: auto;
  backdrop-filter: blur(50px);
  background: rgba(18, 84, 193, 0.1);
  border: 1px solid #1254c1;
  box-shadow: 0px 20px 30px rgba(18, 84, 193, 0.11);
  border-radius: 6px;
  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #092a60;
  z-index: 20;
  left: 50%;
  transform: translate(-50%, 0);
  top: 31px;
}
