.hub-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  background-repeat: no-repeat;
  background-position: center center;
}
.hi-sm {
  width: 14px;
  height: 14px;
}
.hi-md {
  width: 16px;
  height: 16px;
}
.hi-lg {
  width: 20px;
  height: 20px;
}
.hi-home {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 8.80442C2.5 8.32579 2.5 8.08648 2.56169 7.86609C2.61633 7.67087 2.70614 7.48725 2.82669 7.32426C2.96278 7.14026 3.15168 6.99333 3.52949 6.69948L9.18141 2.30355C9.47418 2.07584 9.62057 1.96198 9.78221 1.91821C9.92484 1.8796 10.0752 1.8796 10.2178 1.91821C10.3794 1.96198 10.5258 2.07584 10.8186 2.30355L16.4705 6.69948C16.8483 6.99333 17.0372 7.14026 17.1733 7.32426C17.2939 7.48725 17.3837 7.67087 17.4383 7.86609C17.5 8.08648 17.5 8.32579 17.5 8.80442V14.8335C17.5 15.767 17.5 16.2337 17.3183 16.5902C17.1586 16.9038 16.9036 17.1588 16.59 17.3185C16.2335 17.5002 15.7668 17.5002 14.8333 17.5002H5.16667C4.23325 17.5002 3.76654 17.5002 3.41002 17.3185C3.09641 17.1588 2.84144 16.9038 2.68166 16.5902C2.5 16.2337 2.5 15.767 2.5 14.8335V8.80442Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-teamhub {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.125 17.2917L9.35248 17.9737C9.58881 18.105 9.70698 18.1706 9.83213 18.1964C9.94288 18.2191 10.0571 18.2191 10.1679 18.1964C10.293 18.1706 10.4112 18.105 10.6475 17.9737L11.875 17.2917M4.375 15.2084L3.18581 14.5478C2.93621 14.4091 2.8114 14.3397 2.72053 14.2411C2.64013 14.1539 2.57929 14.0505 2.54207 13.9378C2.5 13.8105 2.5 13.6677 2.5 13.3822V12.0834M2.5 7.91675V6.61795C2.5 6.33242 2.5 6.18965 2.54207 6.06232C2.57929 5.94967 2.64013 5.84627 2.72053 5.75903C2.8114 5.66041 2.93621 5.59108 3.18581 5.45241L4.375 4.79175M8.125 2.70841L9.35248 2.02648C9.58881 1.89518 9.70698 1.82954 9.83213 1.8038C9.94288 1.78102 10.0571 1.78102 10.1679 1.8038C10.293 1.82953 10.4112 1.89518 10.6475 2.02648L11.875 2.70841M15.625 4.79175L16.8142 5.45241C17.0638 5.59108 17.1886 5.66041 17.2795 5.75903C17.3599 5.84627 17.4207 5.94967 17.4579 6.06232C17.5 6.18965 17.5 6.33242 17.5 6.61795V7.91675M17.5 12.0834V13.3822C17.5 13.6677 17.5 13.8105 17.4579 13.9378C17.4207 14.0505 17.3599 14.1539 17.2795 14.2411C17.1886 14.3397 17.0638 14.4091 16.8142 14.5478L15.625 15.2084M8.125 8.95841L10 10.0001M10 10.0001L11.875 8.95841M10 10.0001V12.0834M2.5 5.83341L4.375 6.87508M15.625 6.87508L17.5 5.83341M10 16.2501V18.3334' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-projects {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66675 10.0001L9.70194 14.0177C9.81126 14.0723 9.86591 14.0996 9.92325 14.1104C9.97403 14.1199 10.0261 14.1199 10.0769 14.1104C10.1342 14.0996 10.1889 14.0723 10.2982 14.0177L18.3334 10.0001M1.66675 14.1667L9.70194 18.1843C9.81126 18.239 9.86591 18.2663 9.92325 18.2771C9.97403 18.2866 10.0261 18.2866 10.0769 18.2771C10.1342 18.2663 10.1889 18.239 10.2982 18.1843L18.3334 14.1667M1.66675 5.8334L9.70194 1.8158C9.81126 1.76114 9.86591 1.73381 9.92325 1.72306C9.97403 1.71353 10.0261 1.71353 10.0769 1.72306C10.1342 1.73381 10.1889 1.76114 10.2982 1.8158L18.3334 5.8334L10.2982 9.85099C10.1889 9.90565 10.1342 9.93298 10.0769 9.94374C10.0261 9.95326 9.97403 9.95326 9.92325 9.94374C9.86591 9.93298 9.81126 9.90565 9.70194 9.85099L1.66675 5.8334Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-roadmap {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.84446 8.95373C2.34834 8.7608 2.10028 8.66433 2.02786 8.52533C1.96508 8.40483 1.96499 8.26129 2.02763 8.14071C2.09989 8.00163 2.34784 7.90486 2.84373 7.71134L16.9169 2.21939C17.3645 2.0447 17.5883 1.95736 17.7314 2.00514C17.8556 2.04663 17.953 2.1441 17.9945 2.26831C18.0423 2.41133 17.955 2.63516 17.7803 3.08281L12.2883 17.1559C12.0948 17.6518 11.998 17.8998 11.859 17.972C11.7384 18.0347 11.5948 18.0346 11.4743 17.9718C11.3353 17.8994 11.2389 17.6513 11.0459 17.1552L8.85584 11.5235C8.81667 11.4228 8.79709 11.3725 8.76685 11.3301C8.74004 11.2925 8.70717 11.2596 8.66959 11.2328C8.62719 11.2026 8.57684 11.183 8.47613 11.1438L2.84446 8.95373Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-roadmap {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.84446 8.95373C2.34834 8.7608 2.10028 8.66433 2.02786 8.52533C1.96508 8.40483 1.96499 8.26129 2.02763 8.14071C2.09989 8.00163 2.34784 7.90486 2.84373 7.71134L16.9169 2.21939C17.3645 2.0447 17.5883 1.95736 17.7314 2.00514C17.8556 2.04663 17.953 2.1441 17.9945 2.26831C18.0423 2.41133 17.955 2.63516 17.7803 3.08281L12.2883 17.1559C12.0948 17.6518 11.998 17.8998 11.859 17.972C11.7384 18.0347 11.5948 18.0346 11.4743 17.9718C11.3353 17.8994 11.2389 17.6513 11.0459 17.1552L8.85584 11.5235C8.81667 11.4228 8.79709 11.3725 8.76685 11.3301C8.74004 11.2925 8.70717 11.2596 8.66959 11.2328C8.62719 11.2026 8.57684 11.183 8.47613 11.1438L2.84446 8.95373Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-program {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.50008 1.66666V3.33332M12.5001 1.66666V3.33332M7.50008 16.6667V18.3333M12.5001 16.6667V18.3333M16.6667 7.49999H18.3334M16.6667 11.6667H18.3334M1.66675 7.49999H3.33341M1.66675 11.6667H3.33341M7.33341 16.6667H12.6667C14.0669 16.6667 14.7669 16.6667 15.3017 16.3942C15.7721 16.1545 16.1546 15.772 16.3943 15.3016C16.6667 14.7669 16.6667 14.0668 16.6667 12.6667V7.33332C16.6667 5.93319 16.6667 5.23313 16.3943 4.69835C16.1546 4.22794 15.7721 3.84549 15.3017 3.60581C14.7669 3.33332 14.0669 3.33332 12.6667 3.33332H7.33341C5.93328 3.33332 5.23322 3.33332 4.69844 3.60581C4.22803 3.84549 3.84558 4.22794 3.6059 4.69835C3.33341 5.23313 3.33341 5.93319 3.33341 7.33332V12.6667C3.33341 14.0668 3.33341 14.7669 3.6059 15.3016C3.84558 15.772 4.22803 16.1545 4.69844 16.3942C5.23322 16.6667 5.93328 16.6667 7.33341 16.6667ZM8.83341 12.5H11.1667C11.6335 12.5 11.8668 12.5 12.0451 12.4092C12.2019 12.3293 12.3294 12.2018 12.4093 12.045C12.5001 11.8667 12.5001 11.6334 12.5001 11.1667V8.83332C12.5001 8.36661 12.5001 8.13326 12.4093 7.955C12.3294 7.7982 12.2019 7.67071 12.0451 7.59082C11.8668 7.49999 11.6335 7.49999 11.1667 7.49999H8.83341C8.3667 7.49999 8.13335 7.49999 7.95509 7.59082C7.79829 7.67071 7.6708 7.7982 7.59091 7.955C7.50008 8.13326 7.50008 8.36661 7.50008 8.83332V11.1667C7.50008 11.6334 7.50008 11.8667 7.59091 12.045C7.6708 12.2018 7.79829 12.3293 7.95509 12.4092C8.13335 12.5 8.3667 12.5 8.83341 12.5Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-changeset {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.1667 1.66666L17.5 4.99999M17.5 4.99999L14.1667 8.33332M17.5 4.99999H6.5C5.09987 4.99999 4.3998 4.99999 3.86502 5.27247C3.39462 5.51216 3.01217 5.89461 2.77248 6.36501C2.5 6.89979 2.5 7.59986 2.5 8.99999V9.16666M2.5 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V10.8333M2.5 15L5.83333 18.3333M2.5 15L5.83333 11.6667' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-otherworks {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.99996 17.5L9.91658 17.3749C9.33771 16.5066 9.04828 16.0725 8.66588 15.7582C8.32734 15.4799 7.93726 15.2712 7.51796 15.1438C7.04433 15 6.52255 15 5.47898 15H4.33329C3.39987 15 2.93316 15 2.57664 14.8183C2.26304 14.6586 2.00807 14.4036 1.84828 14.09C1.66663 13.7335 1.66663 13.2668 1.66663 12.3333V5.16667C1.66663 4.23325 1.66663 3.76654 1.84828 3.41002C2.00807 3.09641 2.26304 2.84144 2.57664 2.68166C2.93316 2.5 3.39987 2.5 4.33329 2.5H4.66663C6.53347 2.5 7.46689 2.5 8.17993 2.86331C8.80713 3.18289 9.31707 3.69282 9.63665 4.32003C9.99996 5.03307 9.99996 5.96649 9.99996 7.83333M9.99996 17.5V7.83333M9.99996 17.5L10.0833 17.3749C10.6622 16.5066 10.9516 16.0725 11.334 15.7582C11.6726 15.4799 12.0627 15.2712 12.482 15.1438C12.9556 15 13.4774 15 14.5209 15H15.6666C16.6 15 17.0668 15 17.4233 14.8183C17.7369 14.6586 17.9918 14.4036 18.1516 14.09C18.3333 13.7335 18.3333 13.2668 18.3333 12.3333V5.16667C18.3333 4.23325 18.3333 3.76654 18.1516 3.41002C17.9918 3.09641 17.7369 2.84144 17.4233 2.68166C17.0668 2.5 16.6 2.5 15.6666 2.5H15.3333C13.4664 2.5 12.533 2.5 11.82 2.86331C11.1928 3.18289 10.6828 3.69282 10.3633 4.32003C9.99996 5.03307 9.99996 5.96649 9.99996 7.83333' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-resources {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3333 17.5V15.8333C18.3333 14.2801 17.271 12.9751 15.8333 12.605M12.9166 2.7423C14.1382 3.23679 15 4.43443 15 5.83333C15 7.23224 14.1382 8.42988 12.9166 8.92437M14.1666 17.5C14.1666 15.9469 14.1666 15.1703 13.9129 14.5577C13.5746 13.741 12.9257 13.092 12.1089 12.7537C11.4963 12.5 10.7198 12.5 9.16662 12.5H6.66663C5.11349 12.5 4.33692 12.5 3.72435 12.7537C2.90759 13.092 2.25867 13.741 1.92036 14.5577C1.66663 15.1703 1.66663 15.9469 1.66663 17.5M11.25 5.83333C11.25 7.67428 9.75757 9.16667 7.91662 9.16667C6.07568 9.16667 4.58329 7.67428 4.58329 5.83333C4.58329 3.99238 6.07568 2.5 7.91662 2.5C9.75757 2.5 11.25 3.99238 11.25 5.83333Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-resourceUtilization {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.33331 18.1812C3.83546 18.3333 4.5137 18.3333 5.66663 18.3333H14.3333C15.4862 18.3333 16.1645 18.3333 16.6666 18.1812M3.33331 18.1812C3.22564 18.1486 3.12607 18.1089 3.03165 18.0608C2.56124 17.8212 2.17879 17.4387 1.93911 16.9683C1.66663 16.4335 1.66663 15.7335 1.66663 14.3333V5.66666C1.66663 4.26653 1.66663 3.56646 1.93911 3.03168C2.17879 2.56128 2.56124 2.17882 3.03165 1.93914C3.56643 1.66666 4.26649 1.66666 5.66663 1.66666H14.3333C15.7334 1.66666 16.4335 1.66666 16.9683 1.93914C17.4387 2.17882 17.8211 2.56128 18.0608 3.03168C18.3333 3.56646 18.3333 4.26653 18.3333 5.66666V14.3333C18.3333 15.7335 18.3333 16.4335 18.0608 16.9683C17.8211 17.4387 17.4387 17.8212 16.9683 18.0608C16.8738 18.1089 16.7743 18.1486 16.6666 18.1812M3.33331 18.1812C3.33359 17.5067 3.33763 17.1499 3.39734 16.8497C3.66036 15.5274 4.69402 14.4937 6.01632 14.2307C6.33832 14.1667 6.72553 14.1667 7.49996 14.1667H12.5C13.2744 14.1667 13.6616 14.1667 13.9836 14.2307C15.3059 14.4937 16.3396 15.5274 16.6026 16.8497C16.6623 17.1499 16.6663 17.5067 16.6666 18.1812M13.3333 7.91666C13.3333 9.75761 11.8409 11.25 9.99996 11.25C8.15901 11.25 6.66663 9.75761 6.66663 7.91666C6.66663 6.07571 8.15901 4.58332 9.99996 4.58332C11.8409 4.58332 13.3333 6.07571 13.3333 7.91666Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-timesheets {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5 9.58332V7.33332C17.5 5.93319 17.5 5.23313 17.2275 4.69835C16.9878 4.22794 16.6054 3.84549 16.135 3.60581C15.6002 3.33332 14.9001 3.33332 13.5 3.33332H6.5C5.09987 3.33332 4.3998 3.33332 3.86502 3.60581C3.39462 3.84549 3.01217 4.22794 2.77248 4.69835C2.5 5.23313 2.5 5.93319 2.5 7.33332V14.3333C2.5 15.7335 2.5 16.4335 2.77248 16.9683C3.01217 17.4387 3.39462 17.8212 3.86502 18.0608C4.3998 18.3333 5.09987 18.3333 6.5 18.3333H10.4167M17.5 8.33332H2.5M13.3333 1.66666V4.99999M6.66667 1.66666V4.99999M15 17.5V12.5M12.5 15H17.5' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-myTimesheet {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.83329 14.1667L6.23789 16.1896C6.39061 16.9532 6.46697 17.335 6.66633 17.6206C6.84213 17.8725 7.0841 18.0708 7.36553 18.1938C7.68467 18.3333 8.07404 18.3333 8.85277 18.3333H11.1471C11.9259 18.3333 12.3152 18.3333 12.6344 18.1938C12.9158 18.0708 13.1578 17.8725 13.3336 17.6206C13.5329 17.335 13.6093 16.9532 13.762 16.1896L14.1666 14.1667M5.83329 5.83332L6.23789 3.81035C6.39061 3.04674 6.46697 2.66494 6.66633 2.37934C6.84213 2.1275 7.0841 1.92913 7.36553 1.80613C7.68467 1.66666 8.07404 1.66666 8.85277 1.66666H11.1471C11.9259 1.66666 12.3152 1.66666 12.6344 1.80613C12.9158 1.92913 13.1578 2.1275 13.3336 2.37934C13.5329 2.66494 13.6093 3.04674 13.762 3.81035L14.1666 5.83332M9.99996 7.49999V9.99999L11.25 11.25M15.8333 9.99999C15.8333 13.2217 13.2216 15.8333 9.99996 15.8333C6.7783 15.8333 4.16663 13.2217 4.16663 9.99999C4.16663 6.77833 6.7783 4.16666 9.99996 4.16666C13.2216 4.16666 15.8333 6.77833 15.8333 9.99999Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-resources-dark {
  background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3333 17.5V15.8333C18.3333 14.2801 17.271 12.9751 15.8333 12.605M12.9166 2.7423C14.1382 3.23679 15 4.43443 15 5.83333C15 7.23224 14.1382 8.42988 12.9166 8.92437M14.1666 17.5C14.1666 15.9469 14.1666 15.1703 13.9129 14.5577C13.5746 13.741 12.9257 13.092 12.1089 12.7537C11.4963 12.5 10.7198 12.5 9.16662 12.5H6.66663C5.11349 12.5 4.33692 12.5 3.72435 12.7537C2.90759 13.092 2.25867 13.741 1.92036 14.5577C1.66663 15.1703 1.66663 15.9469 1.66663 17.5M11.25 5.83333C11.25 7.67428 9.75757 9.16667 7.91662 9.16667C6.07568 9.16667 4.58329 7.67428 4.58329 5.83333C4.58329 3.99238 6.07568 2.5 7.91662 2.5C9.75757 2.5 11.25 3.99238 11.25 5.83333Z' stroke='black'  stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.hi-down-arrow {
  background-image: url('data:image/svg+xml,%3Csvg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 0.75L5 4.25L8.5 0.75" stroke="blue" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
}

.hi-columns3 {
  background-image: url("data:image/svg+xml,<svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M5 1V13M9 1V13M4.2 1H9.8C10.9201 1 11.4802 1 11.908 1.21799C12.2843 1.40973 12.5903 1.71569 12.782 2.09202C13 2.51984 13 3.07989 13 4.2V9.8C13 10.9201 13 11.4802 12.782 11.908C12.5903 12.2843 12.2843 12.5903 11.908 12.782C11.4802 13 10.9201 13 9.8 13H4.2C3.07989 13 2.51984 13 2.09202 12.782C1.71569 12.5903 1.40973 12.2843 1.21799 11.908C1 11.4802 1 10.9201 1 9.8V4.2C1 3.07989 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.07989 1 4.2 1Z' stroke='white' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
}
