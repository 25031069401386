.conversations {
  width: 100%;
  display: flex;
  flex-direction: column;
  .convo-wrapper {
    display: flex;
    gap: 14px;
    padding: 37px 20px;
    .label-section {
      width: calc(100% - 108px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2px 0;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    background-color: #e8ecf3;
  }
}
