.hub-rmUtil-header{
    flex: 1;
}

.hub-rmUtil-panel{
    display: flex;
    flex: 1;
    overflow: auto;
}

.hub-rmUtil-panel-left{}

.hub-rmUtil-panel-right{
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hub-rmUtil-panel-wrapper{
    display: flex;
    box-sizing: border-box;
}


.hub-rmUtil-headerCol{
    height: 59px;
    line-height: 59px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #F9FAFC;
}

.hub-rmUtil-panel-row {
    display: flex;
    height: 50px;
    box-sizing: border-box;
    border-bottom: 1px solid #E2E9F1;
}


.hub-rmUtil-panel-row:hover, .hub-rmUtil-panel-row.hover {
    background-color: #F3F6F9 !important;
}

.hub-rmUtil-panel-cell {
    display: flex;
    padding-left: 12px;
    padding-right: 12px;
    height: 49px;
    line-height: 49px;
}

.hub-rmUtil-panel-cell.data {
    border-right: 1px solid #E2E9F1;
    width: 120px;
    box-sizing: border-box;
    justify-content: center;
}

.hub-rmUtil-toolbar{
    box-sizing: border-box;
    height: 60px;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.hub-rm-progressView {
    margin: auto;
    background-color: khaki;
    height: 33px;
    width: 93px;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 13px;
    font-family: Open Sans;
}
.hub-rm-progressView-barWrapper { display: flex; }
.hub-rm-progressView-bar {  background-color: #E9C056;  height: 33px; width: 0px; border-radius: 3px; }
.hub-rm-progressView-barBg { background-color: antiquewhite; height: 33px; border-radius: 3px; }

/* Green Progress View */
.hub-rm-progressView.green .hub-rm-progressView-values { background-color: #F2FFF4; }
.hub-rm-progressView.green .hub-rm-progressView-bar { background-color: #68D279; }
.hub-rm-progressView.green .hub-rm-progressView-barBg { background-color: #CAF1D0; }
.hub-rm-progressView.green .hub-rm-progressView-values > .seperator { color: #68D279; }


/* Yellow Progress View */
.hub-rm-progressView.yellow .hub-rm-progressView-values { background-color: #FFFAED; }
.hub-rm-progressView.yellow .hub-rm-progressView-bar { background-color: #E9C056; }
.hub-rm-progressView.yellow .hub-rm-progressView-barBg { background-color: #F0E6CA; }
.hub-rm-progressView.yellow .hub-rm-progressView-values > .seperator { color: #E9C056 }

/* Red Progress View */
.hub-rm-progressView.red .hub-rm-progressView-values { background-color: #FFEEEE; }
.hub-rm-progressView.red .hub-rm-progressView-bar { background-color: #E37070; }
.hub-rm-progressView.red .hub-rm-progressView-barBg { background-color: #FBD4D4; }
.hub-rm-progressView.red .hub-rm-progressView-values > .seperator { color: #E37070; }



.hub-rm-progressView-values {   
    background-color: #FFFAED; 
    height: 31px;
    width: 93px;
    border-radius: 3px;
    position: relative;
    top: -33px;
    display: flex;
    justify-content: center;
}

.hub-rm-progressView-values > .primaryValue {   
    text-align: right;
    line-height: 31px;
    min-width: 35px;
}
.hub-rm-progressView-values > .secondaryValue {   
    text-align: left;
    line-height: 31px;
    width: 35px;

}
.hub-rm-progressView-values > .seperator {   
    color: #E9C056; 
    padding-left: 2px;
    padding-right: 2px;
    line-height: 31px;
    font-weight: bold;
}




.hub-rmUtil-groupName {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: Open Sans;
    font-size: 14px;
    width: 100%;
}


.hub-rmUtil-groupName.parent {
    font-weight: bold;
    color: #1A2A44;
    cursor: pointer;
}



.hub-rmUtil-groupName .iconWrapper {
    display: flex;
    align-items: center;
    gap: 2px;
    box-sizing: border-box;
    border: 1px solid #EAF0F9;
    border-radius: 5px;
    background-color: #EAF0F9;
    padding: 2px;
    height: 28px;
}



.hub-rmUtil-groupName .iconWrapper .type {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 24px;
}

.hub-rmUtil-groupName .iconWrapper .expand {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    background-color: #ffffff;
    height: 24px;
    border-radius: 4px;
}

.hub-rmUtil-groupName .iconWrapper .expand.collapsed {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    background-color: #ffffff;
    height: 24px;
    border-radius: 4px;
    transform: rotate(-90deg);
}


.hub-rmUtil-groupName:hover .iconWrapper {
    background-color: #FFFFFF;
}

.hub-rmUtil-groupName:hover .iconWrapper .expand {
    background-color: #1D5BBF;
}

.hub-rmUtil-groupName:hover .iconWrapper .type svg {
    color: #1D5BBF;
}


.hub-rmUtil-groupName:hover .iconWrapper .expand svg {
    color: #FFFFFF;
    transform: rotate(-180deg);
    transition: transform 0.4s ease-out;
}

.hub-rmUtil-groupName:hover .iconWrapper .expand.collapsed svg {
    color: #FFFFFF;
    transform: rotate(90deg);
    transition: transform 0.4s ease-out;
}

.hub-rmUtil-total {
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;
}
.hub-rmUtil-total .unit {
    color: #CCCCCC
}

