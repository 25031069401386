.page-area {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-right: 1px solid var(--hub100, #d2def2);
  position: relative;
}

.page-area .button-area {
  position: sticky;
  z-index: 5;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 30px;
  background: #f4f7fc;
  gap: 10px;
  order: 3;
}

.hub-button-wrapper .button-area-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 7px;
  height: 34px;
}

.hub-button-wrapper .button-area-action .label {
  color: #fff;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 138.462% */
}

.page-area .button-area:not(:has(div)) {
  padding: 0px;
}

.page-area .note-area {
  padding: 30px 30px 15px 30px;
  /*  display: flex;
  justify-content: center;
  align-items: center; */
  /*   text-align: left;
  height: 55px; */
  z-index: 0;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 2;
  /*  order: 1; */
}

.note-area p,
ul,
ol {
  margin: 10px 0 0 0;
  padding-top: 0px;
}

.page-area .body-area {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  overflow-y: auto;
  padding: 30px 30px 84px 30px;
  position: relative;
  z-index: 1;
  order: 2;
}

.body-area::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

/* Track */
.body-area::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
.body-area::-webkit-scrollbar-thumb {
  background: #e3e3e3;
  border-radius: 20px;
  padding: 1px;
  border: 7px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
.body-area::-webkit-scrollbar-thumb:hover {
  /* background: #767c85; */
  border-radius: 100px;
  padding: 2px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.body-area .input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

.body-area .check-grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: 30px;
}

.frequency {
  color: var(--hub600, #1254c1);
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px 0;
}
