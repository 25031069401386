.hub-ts-deadline {
  /* background-color: var(--hub50, #e8eff9); */
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 4px 8px;
  height: 22px;
  align-items: center;
}

.hub-ts-deadline-icon {
  height: 17px;
}
.hub-ts-deadline-label {
  color: var(--hub700, #0e3f91);
  text-align: center;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
}
