/*  -- Panel --  */
.hub-rmUtil-datePicker-wrapper { 
    position: absolute;
    z-index: 1090;
    top: 112px;
    right:28px;
    opacity: 0;
    transition-timing-function: ease-out;
    transition: opacity 0.3s;
}

.hub-rmUtil-datePicker-wrapper .arrow {
    background-color: #f7f8fb;
    border: 1px solid #CAD4E4;
    border-width: 1px 1px 0px 0px;
    box-shadow: 0px 20px 50px rgba(15, 60, 132, 0.3);
    border-radius: 0px 2px 0px 0px;
    transform: rotate(-45deg);
    height: 12px;
    width: 12px;
    display: block;
    margin-left: 500px;
    z-index: 1092;
}

.hub-rmUtil-datePicker {   
    background: #FFFFFF;
    border: 1px solid #CAD4E4;
    box-shadow: 0px 20px 50px rgba(15, 60, 132, 0.3);
    border-radius: 10px;
    z-index: 1091; 
    width: 642px;
    height: 362px;
    box-sizing: border-box;
    margin-top: -7px;
}

.hub-rmUtil-datePicker .header {   
    width: 640px;
    height: 50px;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 20px;
    
    background: #F9FAFC;
    box-shadow: 0px 1px 0px #ECF3FE;
    border-radius: 10px 10px 0px 0px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 49px;

    border-bottom: 1px solid #ECF3FE;

    color: #202A39;

    display: flex;
    justify-content: space-between;
}

.hub-rmUtil-datePicker .header .buttonBar{ 
    display: flex;
    flex-direction: row;

}

.hub-rmUtil-datePicker .header .title{ 
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

}

.hub-rmUtil-datePicker .header .desc { 

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    /* Hub Blue 800 */

    color: #202A39;

}


.hub-rmUtil-datePicker .body { 
    width: 640px;
    height: 310px;
    display: inline-block;
    background-color: #FFFFFF;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.hub-rmUtil-datePicker .body .column {   
    width: 320px;
    box-sizing: border-box;
    padding: 20px 20px 38px 20px;
    background-color: #FFFFFF;
    float: left;
    height: 310px;
    overflow: auto;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.hub-rmUtil-datePicker .body .column .detailHeader {
    width: 100%;
    height: 20px;
    box-sizing: border-box;
    line-height: 20px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #1D5BBF;
}

.hub-rmUtil-datePicker .body .layoutItem {   
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 36px;

    color: #202A39;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.hub-rmUtil-datePicker .body .layoutItem.active {   
    background-color: #ECF3FE;
    border-radius: 4px;
}

/*  -- Panel Sonu --  */
.hub-rmUtil-datePicker .pickerWrapper{
    margin-top: 15px;
    display: flex; 
    flex-direction: row;
    gap: 40px;
}
.hub-rmUtil-datePicker .pickerWrapper .pickerColumn {
    width: 192px;
    height: 222px;
}
.hub-rmUtil-datePicker .pickerWrapper .periodSelector {
    height: 28px;
    line-height: 28px;
    display: flex;
}

.hub-rmUtil-datePicker .pickerWrapper .periodSelector .icon { 
    border: 1px solid #F0F0F0;
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.11);
    border-radius: 6px;
    height: 28px;
    width: 28px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    box-sizing: border-box;

    cursor: pointer;
}


.hub-rmUtil-datePicker .pickerWrapper .periodSelector .label {  
    flex: 1;   
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 28px;
    /* identical to box height, or 233% */

    text-align: center;

    /* Hub Blue 800 */

    color: #202A39;


}

.hub-rmUtil-datePicker .pickerWrapper .periodBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .headerRow {
    display: flex;
    flex-direction: row;
}


.hub-rmUtil-datePicker .pickerWrapper .periodBody .headerRow div {
    width:28px;
    box-sizing: border-box;
    text-align: center;
    line-height: 32px;
    color: #CAD4E4;
    font-size: 12px;
    height: 32px;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .row {
    height: 36px;
    display: flex;
    flex-direction: row;
}

  
.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem {
    width: 64px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    display: flex;
    flex-direction: row;
} 


.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.disabled {
    opacity: 0.5;
} 

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.disabled .cellItem { cursor:auto }



.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem:first-child { 
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem:last-child { 
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}


.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem .cellItem {
    width: 56px;
    height: 32px;
    line-height: 30px;
    box-sizing: border-box;
    text-align: center;
    background: #FFFFFF;
    border: 1px solid #E8EBF0;
    border-radius: 6px;
    display: inline-block;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    color: #202A39;
    cursor: pointer;

} 

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem .cellItem.rowHeader {
    color: #CAD4E4;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem .cellItem.today {
    color: #1D5BBF;

} 


/* Month Item Selected, Hovered  */ 

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.selected,.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.hovered, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowHovered {
    background-color: #ECF3FE;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.hovered .cellItem {
    background-color: #ECF3FE;
    border: none;
}


.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.selected .cellItem, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.selected .cellItem.today {
    background-color: #1D5BBF;
    border: none;
    color: #ffffff;
    width: 60px;
    height: 36px;
    line-height: 36px;

} 

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.selectedFinish .cellItem, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.selectedFinish .cellItem.today {
    background-color: #7E8EA7;
    border: none;
    color: #ffffff;
    width: 60px;
    height: 36px;
    line-height: 36px;
} 

/** rowHover ve rowSelect - sadece weekly için workaround , yoruldum **/
.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowHovered, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelected, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelectedFinish {
    border-radius: 6px;
    background-color: #ECF3FE;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowHovered .cellItem,.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelected .cellItem, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelectedFinish .cellItem {
    background-color: #ECF3FE;
    border-radius: 0;
    border: none;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowHovered .cellItem:first-child {
    background-color: #D9E2F0;
    border-radius: 6px;
    color: #202A39;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelected .cellItem:first-child {
    background-color: #1D5BBF;
    border: none;
    color: #ffffff;
    border-radius: 6px;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelectedFinish .cellItem:first-child {
    background-color: #7E8EA7;
    border-radius: 6px;
    color: #ffffff;
}

.hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowHovered .cellItem:last-child, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelected .cellItem:last-child, .hub-rmUtil-datePicker .pickerWrapper .periodBody .monthItem.rowSelectedFinish .cellItem:last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;

}


/*.Month Item Selected*/

.hub-rmUtil-datePicker .footNote {
    height: 32px;
    line-height: 32px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    /* identical to box height */

    text-align: center;

    /* Hub Blue 800 */

    color: #202A39;
}


/*  -- Pickerlar --  */

