.hub-teamHub-card .card-detail-section {
    border-top: 1px solid #D2DEF2;
}

.hub-teamHub-card .card-detail-section .marker {
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border: 1px solid #D2DEF2;
    border-width: 1px 0px 0px 1px;
    transform: rotate(45deg);
    position: relative;
    top: -6px;
    left: 63px;
}

.hub-teamHub-card .card-detail-section .card-section-body {
    padding: 0px 15px 18px 15px;
}

.hub-teamHub-card .card-detail-section .card-section-header {
    height: 30px;
    display: flex;
    justify-content: space-between;
}

.hub-teamHub-card .card-detail-section .card-section-header > .title {
    display: flex;
    gap: 10px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height, or 214% */
    color: #0C244C;



}
.hub-teamHub-card .card-detail-section .card-section-header .button-bar {
    display: flex;
    flex-direction: row;
}