.hub-inputControl-searchField {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px;
  border: 1px solid var(--hub100, #d2def2);
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: default;
  position: relative;
  height: 34px;
  margin-right: 10px;
}

.hub-inputControl-searchField:focus-within {
  outline: 2px solid var(--hub500, #1769f1);
}

.hub-inputControl-searchField > input {
  border: 0;
  outline: none;
  box-shadow: none;
  padding: 6px 2px 6px 5px;
  color: var(--theme-light-900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.hub-inputControl-searchField:hover {
  border: 1px solid var(--theme-light-300, #779dd9);
}

.hub-inputControl-searchField > input::placeholder {
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  opacity: 0.15;
}
