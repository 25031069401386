.hub-dataTable-tableArea-actionBar {
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, #d2def2, #d2def2),
    linear-gradient(0deg, #e8eff9, #e8eff9);
  height: 32px;
}

.hub-dataTable-tableArea-actionButtons {
  display: flex;
  align-items: center;
}

.hub-dataTable-action-bar-info-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;

  height: 32px;
  padding: 0px 1px 0px 1px;
  border-radius: 6px;

  border: 1px solid rgba(165, 189, 229, 1);
  background: linear-gradient(0deg, #f4f7fc, #f4f7fc),
    linear-gradient(0deg, #a5bde5, #a5bde5);
  margin: 0 5px 0 5px;
}

.hub-dataTable-action-bar-info-buttons > .action-bar-info {
  display: flex;
  align-items: center;

  font-family: Noto Sans;
  font-size: 11px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(23, 105, 241, 1);
  background: rgba(232, 239, 249, 1);
  padding: 7.5px 10px 7.5px 10px;

  box-shadow: 0px 1px 2px 0px rgba(6, 18, 38, 0.02);
  margin-right: 3px;
}
