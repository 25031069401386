@mixin text-style(
  $color,
  $fontFamily,
  $fontSize,
  $fontStyle,
  $fontWeight,
  $lineHeight
) {
  color: $color;
  font-family: $fontFamily;
  font-size: $fontSize;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

.hub-new-modal {
  position: absolute;
  height: auto;
  max-height: 35.6rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70rem;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 50px 100px 0px rgba(5, 21, 48, 0.25);
  z-index: 1030;
  animation: slide-down 400ms ease-out forwards;
  box-sizing: border-box;

  .title-section {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 12px 12px 0px 0px;
    .title {
      margin: 0 !important;
      @include text-style(
        var(--hub900, #051530),
        "Noto Sans",
        16px,
        normal,
        600,
        34px
      );
    }
  }

  .content {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    padding: 0 16px 16px 16px;
    overflow-y: auto;
    max-height: 500px;
    &::-webkit-scrollbar {
      width: 0.7rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #d9d9d9;
      border-radius: 0.4rem;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 0.4rem;
    }
  }
}

@media (750px >= width) {
  .modal {
    width: 35rem;
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-150%) translateX(-50%);
  }
  to {
    opacity: 1;
    transform: translateY(-50%) translateX(-50%);
  }
}
