.hub-new-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: var(--hub900, #051530);
  opacity: 0.3;
}
