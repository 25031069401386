.profile-settings {
  .hub-new-avatar-section {
    width: 477px;
    display: flex;
    justify-content: center;
    align-items: center;
    .hub-new-avatar-wrapper {
      position: relative;
      .avatar-action-buttons {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 10;
      }
      .avatar-overlay {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 8px;
        cursor: pointer;
        width: 128px;
        height: 128px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #051530;
        opacity: 0.6;
        z-index: 5;
        padding-top: 33px;
        p {
          color: var(--Gray-White, #fff);
          text-align: center;
          font-family: "Noto Sans";
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          margin: 0;
        }
      }
      .avatar-overlay.hide {
        display: none;
      }
    }
  }
  .user-name {
    margin: 0;
    color: var(--hub900, #051530);
    text-align: center;
    font-family: "Noto Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 23.4px */
    padding-bottom: 5px;
    padding-top: 12px;
  }

  .user-role {
    margin: 0;
    color: #647795;
    text-align: center;
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 18.2px */
    padding-bottom: 26px;
  }
}
