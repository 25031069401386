.hub-dt-th-basic {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0px 11px 1px 10px;
  background: var(--hub25, #f4f7fc);
  box-shadow: -1px 0px 0px 0px #d2def2 inset, 0px -1px 0px 0px #d2def2 inset;
  overflow: hidden;
  white-space: nowrap;
}
