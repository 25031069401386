.fl { float:left }
.fr { float:right }
.cb { clear: both; }
.pointer {cursor: pointer;}



.icon-container { width: 32px; }

.flex-container {display: flex; flex-flow: row nowrap;   }


input[type="checkbox"] {
    border-color: rgba(0, 0, 0, 0.6);
    width: 16px;
    height: 16px;
    display: block;
    margin: 0;
}


/*
    Notifications
*/

.hub-event-notification {
    background-color: #ff0000;
}

.hub-event-notification.success {

}