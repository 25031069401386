.toggle-button-group {
  display: flex;
  width: fit-content;
  height: 36px;
  padding: 4px;
  gap: 4px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid rgba(74, 124, 204, 0.2);
  background: var(--white, #fff);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;

  .toggle-button {
    display: flex;
    padding: 0px 15px;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;
    border-radius: 4px;
    border: none;
    background-color: #fff;
    font-family: "Noto Sans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--hub900, #051530);

    &:hover {
      background: var(--hub50, #e8eff9);
    }

    &.active {
      background: var(--hub500, #1769f1);
      box-shadow: 0px 1px 2px 0px rgba(6, 18, 38, 0.02);
      color: var(--white, #fff);
    }
  }
}
