.hub-nav {
  background: var(--white);
  width: 70px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 0;
  box-sizing: content-box;
  border-right: 1px solid var(--hub100);
  z-index: 750;
}

.hub-nav .hub-navMenu {
  background: var(--hub800);
  width: 60px;
  height: 100%;
  margin: 0 5px 5px 5px;
  overflow: hidden;
  border-radius: var(--radius-lg);
  transition: all ease-out 0.3s;
}
.hub-nav .hub-navMenu:hover {
  width: 220px;
}
.hub-nav .hub-navMenu .hub-navLogo {
  text-align: center;
  width: 100%;
  height: 40px;
  margin: 30px 0;
  text-align: center;
  position: relative;
}
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoCollapse,
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoExpand {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all ease-out 0.3s;
}
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoCollapse img,
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoExpand img {
  height: 100%;
}
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoCollapse {
  left: 10px;
}
.hub-nav .hub-navMenu .hub-navLogo .hub-navLogoExpand {
  opacity: 0;
  left: 40px;
}
/** Nav Logo - ARP **/
.hub-nav .hub-navMenu:hover .hub-navLogo-arp .hub-navLogoCollapse {
  transform: rotate(-54deg);
  top: 3px;
  left: 64px;
  height: 50px;
}
.hub-nav .hub-navMenu:hover .hub-navLogo-arp .hub-navLogoExpand {
  opacity: 1;
  top: 0;
  left: 85px;
  height: 100%;
}
/** Nav Logo - BEAT **/
.hub-nav .hub-navMenu:hover .hub-navLogo-beat .hub-navLogoCollapse {
  transform: rotate(360deg);
  left: 10px;
}
.hub-nav .hub-navMenu:hover .hub-navLogo-beat .hub-navLogoExpand {
  opacity: 1;
  top: 0;
  left: 52px;
  height: 100%;
}
.hub-nav .hub-navMenu .hub-navMenuItems {
  padding: 0 10px;
  box-sizing: content-box;
}
.hub-nav .hub-navMenu .hub-navMenuItems a.routerHome {
  padding: 0;
  margin: 0;
}
.hub-nav .hub-navMenu .hub-navMenuItems a {
  text-decoration: none;
  color: var(--white);
  padding: 0 10px;
  margin: 0 0 10px 0;
  min-width: 40px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  box-sizing: border-box;
  border-radius: var(--radius-lg);
  transition: all ease-out 0.1s;
}
.hub-nav .hub-navMenu .hub-navMenuItems a:hover {
  background-color: var(--hub700);
}
.hub-nav .hub-navMenu .hub-navMenuItems a.hub-navActive {
  background-color: var(--hub500);
}
.hub-nav .hub-navMenu .hub-navMenuItems a .hub-icon {
  float: left;
  height: 100%;
}
.hub-nav .hub-navMenu .hub-navMenuItems a .hub-navMenuName {
  opacity: 0;
  height: 40px;
  font-size: var(--size-sm);
  line-height: 40px;
  visibility: hidden;
  overflow: hidden;
  transition: all ease-out 0.3s;
}
.hub-nav .hub-navMenu:hover .hub-navMenuItems a .hub-navMenuName {
  padding-left: 15px;
  opacity: 1;
  visibility: visible;
}
