
.hub-rmUtil-buttonSelect {
   position: relative;
   background: #FFFFFF;
   border: 1px solid var(--hub100);
   border-radius: 6px;
}


.hub-rmUtil-buttonSelect> .drop-down {
   position: absolute;


   float: none;
   min-width: 180px;
   max-height: 358px;
   background-color: #FFFFFF;
   border: none;
   border-radius: 6px;
   box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
   
   z-index: 15;
   padding: 0;
   font-size: 14px;
   overflow-y: auto;
  
   left:-5px;
   top:35px

}


.hub-rmUtil-buttonSelect>.drop-down>.list {
   display: flex;
   flex-direction: column;
}



.hub-rmUtil-buttonSelect>.drop-down>.list>.items {
   color: #1d5bbf;
   padding: 3px 3px 3px 12px;
   display: block;
   border-width: 1px;
  
   display: flex;
   flex-direction: row;
   gap: 15px;
   align-items: center;
   font-weight: 600;
      line-height: 2;
     text-transform: none;

}



.hub-rmUtil-buttonSelect>.drop-down>.list>.items:hover {
   background-color: rgb(187, 195, 231);
}