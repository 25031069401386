body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #07245311;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c0cad8;
  border-radius: 100px;
  padding: 1px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #767c85;
  border-radius: 100px;
  padding: 2px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
