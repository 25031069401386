.react-datepicker {
    padding: 16px;
    background-color: #fff;
    border: none;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    z-index: 1000;
    margin-top: 4px;
}

.react-datepicker__navigation {
    top: 16px;
}

.react-datepicker__navigation--previous{
    left: 16px;
}


.react-datepicker__navigation--next{
    right: 16px;
}
.react-datepicker__header  {
    border: 0;
}

.react-datepicker__navigation-icon--previous::before, .react-datepicker__navigation-icon--next::before {
    border-color:#1d5bbf;
}

.react-datepicker__current-month {
    font-family: "Open Sans";
    font-size: 14px;
    color: #1d5bbf;
}

.react-datepicker__header {
    background-color: #FFFFFF;
}

.react-datepicker__day-name, .react-datepicker__time-name {
    font-size: 14px;
    font-weight: bold;
    font-family: "Open Sans";
    width: 38px;
    box-sizing: border-box;
    margin-top: 12px  !important;
}


.react-datepicker__day  {
    padding: 5px 10px;
    width: 38px;
    font-size: 12px;
    color: rgba(0,0,0,.87);
    font-weight: 400;
    border: 1px solid transparent;
    font-size: 14px;
    min-height: 32px;
    box-sizing: border-box;
    font-family: "Open Sans";
    cursor: pointer;
}

.react-datepicker__day--selected {
    background: #3272d9;
    color: #fff;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
    border-bottom-color: #FFFFFF;
}