.hub-rmUtil-layoutMenu-wrapper {
  position: absolute;
  z-index: 1090;
  top: 112px;
  left: 74px;
  opacity: 0;
  transition-timing-function: ease-out;
  transition: opacity 0.3s;
}

.hub-rmUtil-layoutMenu-wrapper .arrow {
  /*background: #F9FAFC;*/
  background-color: #f7f8fb;
  border: 1px solid #cad4e4;
  border-width: 1px 1px 0px 0px;
  box-shadow: 0px 20px 50px rgba(15, 60, 132, 0.3);
  border-radius: 0px 2px 0px 0px;
  transform: rotate(-45deg);
  height: 12px;
  width: 12px;
  display: block;
  margin-left: 30px;
  z-index: 1092;
}

.hub-rmUtil-layoutMenu {
  background: #ffffff;
  border: 1px solid #cad4e4;
  box-shadow: 0px 20px 50px rgba(15, 60, 132, 0.3);
  border-radius: 10px;
  z-index: 1091;

  margin-top: -7px;
}

.hub-rmUtil-layoutMenu .header {
  width: 640px;
  height: 50px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  background: #f9fafc;
  box-shadow: 0px 1px 0px #ecf3fe;
  border-radius: 10px 10px 0px 0px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 49px;

  border-bottom: 1px solid #ecf3fe;

  color: #202a39;

  display: flex;
  justify-content: space-between;
}

.hub-rmUtil-layoutMenu .header .buttonBar {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.hub-rmUtil-layoutMenu .body {
  width: 640px;
  display: inline-block;
}

.hub-rmUtil-layoutMenu .body .column {
  width: 320px;
  box-sizing: border-box;
  padding: 20px 30px 38px 20px;
  background-color: #ffffff;
  float: left;
  height: 352px;
  overflow: auto;
}

.hub-rmUtil-layoutMenu .body .column.bg {
  background-color: #f9fafc;
}

.hub-rmUtil-layoutMenu .body .layoutItem {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-bottom: 1px solid #ecf3fe;
  padding-left: 10px;
  padding-right: 10px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 41px;

  color: #202a39;
  cursor: pointer;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hub-rmUtil-layoutMenu .body .layoutItem.active {
  background-color: #ecf3fe;
  border-radius: 4px;
}

.hub-rmUtil-layoutMenu .body .layoutItemList {
  width: 100%;
  height: 294px;
  overflow: auto;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails {
  width: 100%;
  height: 294px;
  overflow: auto;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails .detailHeader {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  line-height: 42px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 42px;

  color: #1d5bbf;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails .detailItem {
  display: flex;
  height: 28px;
  gap: 8px;
  align-items: center;
  margin-top: 10px;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails .detailItem:first-of-type {
  margin-top: 10px;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails .detailItem .icon {
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  line-height: 28px;
  text-align: center;
  border-radius: 5px;
}

.hub-rmUtil-layoutMenu .body .layoutItemDetails .detailFields {
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  line-height: 42px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 42px;

  color: #1d5bbf;
}
