
.hub-input{
    position: relative;
}


.hub-input-lookup{
display: flex;
flex-direction: row;
    position: relative;
    box-shadow: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    font-family: 'Open Sans';
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    background-color: transparent;
    font-family: 'Open Sans';
    transition: all .2s;
    overflow-x: hidden;
    text-overflow: ellipsis;
    padding: 3px;
     resize: none;
    -webkit-user-modify: read-write;
    cursor:text

}


.hub-input-lookup > .icon { 
    margin-right: 3px;
}


.lookup-dropdown {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}