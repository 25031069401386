.hub-navProfile {
  cursor: pointer;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  .hub-navProfile-wrapper {
    padding: 1px;
    border-radius: 50%;
  }
}

.hub-profile-option {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 10px;
  border-radius: 3px;
  height: 34px;
  padding: 0 10px;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  &:hover {
    cursor: pointer;
    background: var(--hub25, #f4f7fc);
  }
}

.hub-user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 15px;
  gap: 2px;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e8eff9;
  .user-name {
    color: var(--theme-light-900, #051530);
    font-family: "Noto Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 20.8px */
  }
  .user-role {
    color: #647795;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 15.6px */
  }
  p {
    max-width: 100%;
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .divider {
    height: 1px;
    width: 100%;
    color: #e8eff9;
  }
}

.hub-navProfile-wrapper.active {
  border: 1px solid #1769f1;
}
