.hub-changeSet-activityTable {
    display: flex;
    flex-direction: row;
    padding: 16px;
    overflow: auto ;
    flex: 1;
    z-index: 2;
    
}
.hub-changeSet-activityTable table {

}

.hub-changeSet-activityTable > .activities {
    width: 30%;
    overflow:auto;
    padding: 0px 2px 0px 2px;
    border: 1px solid rgba(0,0,0,.15);

}

.hub-changeSet-activityTable > .hubPlan {
    width: 35%;
    overflow:auto;
    border-left: 2px solid #afbacc;
    padding: 0px 2px 0px 2px;
    border: 1px solid rgba(0,0,0,.15);

}

.hub-changeSet-activityTable > .p6Plan {
    width: 35%;
    overflow:auto;
    border-left: 2px solid #afbacc;
    padding: 0px 2px 0px 2px;
    border: 1px solid rgba(0,0,0,.15);
}

.hub-changeSet-activityTable .tableHeader {
    text-align: left;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    font-family: Open Sans,sans-serif;
    color: rgba(0,0,0,.64);
    text-align: left;
    height: 32px;
    line-height: 32px;    
    background: #f5f7fa;
    border-bottom: 1px solid #d1d2d5;
    padding-left: 8px;
    box-sizing: border-box;
}


.hub-changeSet-activityTable th {
    text-align: left;
    white-space: nowrap;
    font-weight: 600;
    font-size: 12px;
    font-family: Open Sans,sans-serif;
    color: rgba(0,0,0,.64);
    text-align: left;
    height: 32px;
    line-height: 32px;    
    background: #f5f7fa;
    border-bottom: 1px solid #d1d2d5;
}

.hub-changeSet-activityTable td {
    height: 32px;
    font-size: 14px;
    font-family: Open Sans,sans-serif;
    color:rgba(0,0,0,.95);
    line-height: 26px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    text-align: left;
    border-bottom:1px solid rgba(26,26,26,.15);
    white-space: nowrap;
    overflow:hidden;
}


.hub-changeSet-changes {
    border: 1px solid #eeeeee;
    background-color: #ffffff;
    box-sizing: border-box;
    position: fixed;
    min-width: 250px;
    min-height: 80px;
    z-index: 2;
    font-family: 'Open Sans', Courier, monospace;
    font-size: 13px;
    padding: 8px 16px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 20%);
    z-index: 99;
}

.hub-changeSet-changes>.change-info-wrapper{
    display: flex;
    flex-direction: column;

}

.hub-changeSet-activityTable .td-green {
    font-weight: 500;
    color: rgb(25, 71, 10); 
    background-color: rgb(210, 240, 199);
}

.hub-changeSet-activityTable .td-red {
    font-weight: 500;
    height: 32px;
    color: rgb(120, 24, 13);
    background-color: rgb(250, 223, 220);
}

.hub-changeSet-activityTable .td-blue {
    font-weight: 500;
    height: 32px;
    color: #214352;
    background-color:#d5ebf5;
}

.hub-changeSet-activityTable-warnRed {
    --fa-secondary-color: rgb(120, 24, 13);
    --fa-primary-color: rgb(250, 223, 220);
}


.hub-changeSet-activityTable-warnRed {
    --fa-secondary-color: red;
    --fa-primary-color: black;
}



.hub-changeSet-activityTable-warnBlue {
    --fa-secondary-color: rgb(120, 24, 13);
    --fa-primary-color: rgb(250, 223, 220);
}


.hub-changeSet-activityTable-warnGreen {
    --fa-secondary-color: #3f9e20;
    --fa-primary-color: #19470a ;
}


.hub-changeSet-activityTable-warnYellow {
    --fa-secondary-color: #f7b440;
    --fa-primary-color:#454208;
}

