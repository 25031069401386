.noteComp {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ecf3fe;
  padding: 15px 30px;
  position: relative;
}

.noteComp.highlighted {
  background-color: var(--hub25, #f4f7fc);
}

.note-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 4px;
}

.note-info > .name {
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 18.2px */
}

.note-info > .divider {
  width: 1px;
  height: 10px;
  background-color: #e8eff9;
}

.note-info > .date {
  color: #647795;
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 14.3px */
}

.note-info > .edit {
  display: flex;
  /*burası*/
  opacity: 0;
  font-weight: bold;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
}

.note-info > .edit > .edit-icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #f9fafc79;
  box-shadow: 3px 3px 3px #d8d5d53d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.note-info > .edit-not-visible {
  display: none;
}

.noteComp:hover .edit {
  opacity: 1;
  cursor: pointer;
}

.right-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
}

.right-panel .note-description p {
  min-height: 18.2px;
  color: #000;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  padding: 0 !important;
  margin: 0 !important;
}

.right-panel .note-description .expand-note {
  cursor: pointer;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #647795;
  gap: 6px;
  /* Noto Sans/12 Semibold */
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 15.6px */
}

.right-panel .note-description .expand-note:hover {
  opacity: 0.75;
}

.note-update {
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}

.note-update > .buttonArea {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.note-info .note-options-wrapper {
  position: absolute;
  right: 30px;
}

.note-info .note-options-wrapper .note-settings {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f4f7fc;
  padding: 0;
  border: 1px solid #fff;
}

.note-info .note-options-wrapper .note-settings:hover {
  border: 1px solid #a5bde5;
}

.note-info .note-options-menu {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  width: 85px;
  height: 85px;
  right: 0;
  top: 30px;
  z-index: 50;
  background: #ffffff;
  border: 1px solid #d2def2;
  box-shadow: 0px 20px 30px 0px rgba(18, 84, 193, 0.25);
  border-radius: 6px 0 6px 6px;
}

.note-info .note-options-menu .note-options-menu-item {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  width: 83px;
  height: 40px;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.note-options-menu-item:hover {
  background-color: #e8eff9;
  width: 83px;
  cursor: pointer;
}

.note-menu-divider {
  width: 83px;
  height: 2px;
  background: #f4f7fc;
}

.letters {
  display: inline-block;
  font-size: 1em;
  width: 40px;
  height: 40px;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin-right: 15px;
  color: white;
  position: relative;
}

.main {
  position: absolute;
  left: 185;
}

.note-list {
  display: flex;
  flex-direction: column;
}

.no-comment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 10px;
  padding-top: 25px;
  p {
    color: var(--hub300, #779dd9);
    margin: 0 !important;
  }
}

.right-panel .files-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px 0px 5px 0px;
}

/* .right-panel .note-reply-button {
  .hub-button-wrapper {
    button {
      .label {
        color: #051530;
      }
    }
  }
} */

.files-wrapper {
  .file-info-wrapper {
    position: relative;
    .main-icon {
      display: flex;
      align-items: center;
    }
    .file-info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 7px;
      position: absolute;
      width: fit-content;
      height: auto;
      top: 30px;
      z-index: 50;
      background: #ffffff;
      border: 1px solid #d2def2;
      box-shadow: 0px 20px 30px 0px rgba(18, 84, 193, 0.25);
      border-radius: 6px;
      position: absolute;
      left: 0;
      .description {
        display: flex;
        flex-direction: column;
        gap: 3px;
        padding-left: 8px;
        padding-right: 15px;
        min-width: max-content;

        .file-name {
          color: #202a39;
          font-family: "Noto Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 130%; /* 15.6px */
        }
        .file-summary {
          display: flex;
          align-items: center;

          .size {
            display: flex;
            align-items: center;
            gap: 4px;
            color: #647795;
            font-family: "Noto Sans";
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%; /* 14.3px */
          }
        }
      }
    }
  }
}

.note-delete-content {
  display: flex;
  flex-direction: column;
  width: 300px;
  .delete-typo {
    color: var(--hub900, #051530);
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
    margin: 0;
    padding: 26px 0 12px 0;
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    .cancel {
      .hub-button-wrapper {
        button {
          .label {
            color: #0e3f91;
          }
        }
      }
    }
  }
}
