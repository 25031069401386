.change-set-label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    font-size: large;
    height: 32px;
    gap:10px;
   

}