.hub-dt-td {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-right: 1px solid rgba(14, 63, 145, 0.1);
  border-bottom: 1px solid rgba(14, 63, 145, 0.1);
  overflow: hidden;
  white-space: nowrap;
}
