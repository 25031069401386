.hub-inputControl {
  float: left;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
}

.hub-inputControl > .label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  height: 28px;
}

.hub-inputControl > .label > .label-typo {
  align-self: stretch;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 233.333% */
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hub-inputControl > .label > .required-symbol {
  color: #dc3545;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 200;
  line-height: 28px; /* 200% */
}

.hub-inputControl > .label > .label-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5px;
}

.hub-inputControl > .input {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px;
  border: 1px solid var(--hub100, #d2def2);
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: default;
  position: relative;
  /* */
  height: 38px;
}

.hub-inputControl > .input:hover {
  border: 1px solid var(--theme-light-300, #779dd9);
}

.hub-inputControl > .multi .multi-disabled {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: default;
  position: relative;
  /* */
  height: 38px;
}

.hub-inputControl > .input-disabled {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s;
  cursor: not-allowed;
  position: relative;
  border-radius: 6px;
  border: 1px solid #f2f4f6;
  background: #f2f4f6;
  height: 38px;
  padding: 0 10px;
}

.hub-inputControl > .input input,
textarea,
select {
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  transition: all 0.2s;
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 100%;
  resize: none;
  color: var(--theme-light-900, #051530);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.hub-inputControl > .input textarea {
  padding: 10px 0;
}

.hub-inputControl.textarea > .input {
  height: auto !important;
}

.hub-inputControl > .input-disabled > input {
  border: none;
  box-shadow: none;
  outline: none;
  width: 100%;
  background-color: transparent;
  transition: all 0.2s;
  overflow-x: hidden;
  text-overflow: ellipsis;
  cursor: not-allowed;
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  opacity: 0.4;
}

.hub-inputControl > .message {
  height: 15px;
  overflow: hidden;
  color: #dc3545;
  font-family: "Noto Sans";
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 166.667% */
}

.hub-inputControl > .message.yellow {
  color: #c9a576;
}

.hub-inputControl > .input > .percentage-char {
  position: absolute;
  left: 37px;
  /**/
  top: 8.5px;
}

.hub-inputControl > .input-disabled > .percentage-char {
  position: absolute;
  left: 37px;
  /**/
  top: 8.5px;
}

.hub-inputControl.active > .label {
  color: #1d5bbf;
}
.hub-inputControl.active > .input {
  /* outline: 2px solid var(--hub500, #1769F1); */
  border: 1px solid var(--hub500, #1769f1);
}

.hub-inputControl.error > .label {
  color: #de1b1b;
}
.hub-inputControl.error > .input {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #de1b1b;
}

.hub-inputControl.warning > .label {
  color: #c9a576;
}

.hub-inputControl.warning > .input {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #c9a576;
}

.hub-inputControl.to-do-input {
  height: 28px;
}

.hub-inputControl.to-do-input .input {
  height: 28px;
}

.hub-inputControl.to-do-input > .input > input {
  height: 10px;
  padding-top: 5px;
}
