.hub-pageFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-bottom: 1px solid var(--hub100, #d2def2);
}

.hub-pageFilter-button-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
