.hub-ts-timesheetDetailPage{
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 12px;
    padding-top: 0;
}

.hub-ts-timesheetDetailPage-Header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-content: baseline;
    align-items: baseline;
    padding: 16px 10px 12px 10px;
}

.hub-ts-ReportPeriodPicker {
    display: flex;
    flex-flow: row;
    justify-content: first baseline;
}

.hub-ts-ReportPeriodPicker > .arrows {
    box-sizing: border-box;
    display: inline-block;
    height: 58px;
    padding: 20px 15px;
}
.hub-ts-ReportPeriodPicker  svg {
   cursor: pointer;
}

.hub-ts-ReportPeriodPicker > div {
    display: flex;
    gap:8px;
    align-items: center;
}

.hub-ts-ReportPeriod {
    box-sizing: border-box;
    width: 130px;
    height: 58px;
    padding: 12px;
    cursor: pointer;
    outline: initial;
    border-radius: 8px;
    border: 1px solid #E0E8F3;
    background: #FFF;
    color: #B5C1D1;
}

.hub-ts-ReportPeriod.selected {
    border-radius: 8px;
    border: 1px solid #134FB2;
    background: #1769F1;
    outline: none;
}

.hub-ts-ReportPeriod > .date {
    color: #B5C1D1;
    font-family: Open Sans;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; 
    text-decoration: none;
}

.hub-ts-ReportPeriod.selected > .date { color:#FFFFFF }

.hub-ts-ReportPeriod > .status {
    color: #B5C1D1;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis
}

.hub-ts-ReportPeriod.selected > .status {
    color:#FFFFFF;
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis
}

.hub-ts-ReportPeriodPicker a {
    text-decoration: none;
}

.hub-ts-progressBar {
    width: 100%;
    max-width: 30%;
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
    flex-wrap: wrap;
    font-size: .9rem;
    color: rgba(0,0,0,.6);
}

.hub-ts-progressBar > .units { }

.hub-ts-progressBar > .usage { }

.hub-ts-progressBar-graph { width: 100%; }

.hub-ts-progressBar-graph > .graph-inner {
    background-color: #cccccc;
    border-radius: 3px;
    margin: 0 0 10px;
    height: 10px;
    width: 100%;
    border-radius: 10px;
    background: #dde3ed;
    border: none;
}

.hub-ts-progressBar-graph > .graph-inner {
    background-color: #cccccc;
    border-radius: 3px;
    margin: 0 0 10px;
    height: 6.5px;
    padding-top: 3.5px;
    width: 100%;
    border-radius: 10px;
    background: #dde3ed;
    border: none;

}
.hub-ts-progressBar-graph > .graph-inner > .graph-line {
    background-color: #2a854e;
    height: 3px;
    width:0%;
    transition: width 1s;
    float: left;
    border-radius: 1px;
} 
.hub-ts-progressBar-graph > .graph-inner > .graph-line-border {
    width: 1px;
    height: 20px;
    float: left;
    border-radius: 1px;
    border-right: 1px solid #000000;
    margin-top: -8px;
    margin-left: -1px;
} 


.hub-ts-actionButtons {
    box-sizing: border-box;
    padding-right: 22px;
    padding-left: 22px;
    display: flex;
    gap: 10px;
}

.hub-ts-actionButtons > button {
    margin: 4px;
}

.hub-ts-timesheetTableContainer {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;

}

.hub-ts-timesheetTableWrapper {
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.hub-ts-timesheetTable {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
}

.hub-ts-timesheetTableHeader  {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 100px;
    border-radius: 8px;
}

.hub-ts-timesheetTableBody  {
    display: flex;
    flex-direction: column;
    overflow: auto;
    flex: 1;
    margin-top: 8px;
    gap: 8px;
}

.hub-ts-timesheetTableRow {
    display: flex;
    /*border-radius: 8px;
    border: 1px solid #E0E8F3;
    background: #FFF;*/
}

.hub-ts-timesheetTable-th-totals { 
    flex:0.96;
    min-width: 250px;
    max-width: 500px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 32px;
    padding: 0px 16px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border: 1px solid #E0E8F3;
    border-right: 0px;
    height: 98px;
}

.hub-ts-timesheetTable-th-total-unit {
    color: #121212;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px; /* 128.571% */
}

.hub-ts-timesheetTable-th-total-label {
    color: var(--hub-500, #1769F1);
    font-family: Open Sans;
    font-size: 8px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.96px;
    text-transform: uppercase;   
    text-align: center;
}


.hub-ts-timesheetTable-th { 
    width: 114px;
    box-sizing: border-box;
    height: 98px;
    background: #FFFFFF;
    color: rgba(0,0,0,.6);
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    border: 1px solid rgba(0,0,0,.15);
    border-right: 0px;
    word-break: break-all;
    padding: 8px 12px;
} 

.hub-ts-timesheetTable-th-body {
    min-height: 82px;
    display: flex;
    flex-flow: column;
    gap: 12px;
    justify-content: center;
    width: 89px;    
}

.hub-ts-timesheetTable-th-title { 
    color: #B5C1D1;
    text-align: center;
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
 }

 .hub-ts-timesheetTable-th-label { 
    color: #121212;
    text-align: center;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15.6px; /* 130% */
    letter-spacing: 1.2px;
    text-transform: uppercase;
}

 .hub-ts-timesheetTable-th-total { 
    color: #B5C1D1;
    text-align: center;
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px; /* 130% */
    letter-spacing: 1px;
    text-transform: uppercase; }
    

 .hub-ts-timesheetTable-th.exception .hub-ts-timesheetTable-th-label, .hub-ts-timesheetTable-th.remaining .hub-ts-timesheetTable-th-label { 
    color: #B5C1D1;    
}
 .hub-ts-timesheetTable-th.remaining { 
    color: #B5C1D1;    
    
}

/* is Complete */

.hub-ts-timesheetTable-th.done {
    min-width: 80px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: 1px solid rgba(0,0,0,.15);
    width: 80px;
}

.hub-ts-timesheetTable-th.done>div {
    min-width: 54px;
    width: 54px;
}
.hub-ts-timesheetTable-th.done .hub-ts-timesheetTable-th-label {
    color: #B5C1D1;
}

.hub-ts-timesheetTableAdd {
    height: 57px;
    box-sizing: border-box;
    margin-top: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: 2px dashed #E0E8F3;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(6, 18, 38, 0.02);
}

.hub-ts-timesheetTable-td { 
    background: #FFFFFF;    
    border: 1px solid #ddd;
    border-right: 0px;
    padding: 8px;
    min-width: 114px;
    width: 114px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}


.hub-ts-timesheetTable-td .remaining { 
    flex: 1;
    align-items: center;
    padding-top:32px;
    display: flex;
}

.hub-ts-timesheetTable-td .total { 
    height: 32px;
    color: #B5C1D1;

    text-align: center;
    font-family: "Noto Sans";
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 14px; /* 130% */
    letter-spacing: 1.2px;
    text-transform: uppercase;

    display: flex;
    align-items: flex-end;

}

.hub-ts-timesheetTable-td.task { 
    font-weight: 600;
    color: rgba(0,0,0,.87);
    padding: 16px 8px 8px 16px;
    flex:1;
    min-width: 250px;
    max-width: 500px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}


.hub-ts-timesheetTable-td.task .task-name {
    margin-top: 4px;
}

.hub-ts-timesheetTable-td.task .task-name > a{
    color: #121212;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration: none;
}

.hub-ts-timesheetTable-td.task .icon {
    z-index: 1000;
    cursor:pointer;
    margin-right: 8px;
    position: relative;
    border-radius: 5px;
    padding: 5px;
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.hub-ts-timesheetTable-td.task .icon:hover {
    background: var(--theme-light-255005, rgba(84, 33, 173, 0.05));
}

.hub-ts-timesheetTable-td.task .icon.open { border-radius: 5px 5px 5px 0px; background: var(--hub-500, #1769F1); }
.hub-ts-timesheetTable-td.task .icon.open > svg { color: #FFFFFF; }
.hub-ts-timesheetTable-td.task .icon.open > .hub-context-menu { top:27px; right: 0}

.hub-ts-timesheetTable-td.task .icon .iconMenu {
    position: absolute;
    background-color: #15233b;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: normal;
    padding: 4px 16px;
    width: 160px;
    box-sizing: border-box;
    line-height: 20px;
    border-radius: 4px;
    border-top-left-radius: 0;
}   

.hub-ts-timesheetTable-td.task .header { display:flex; flex-direction: row; align-items: center; gap: 8px;}
.hub-ts-timesheetTable-td.task .header > .task-code { 
    min-width: 54px; 
    box-sizing: border-box;
    max-width: 110px; 
    height: 23px;
    overflow: hidden; 
    padding: 0px 8px;
    background-color: var(--hub-50, #E8EFF9);
    border-radius: 4px;
    color: #0E3F91;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px; /* 180% */
}
.hub-ts-timesheetTable-td.task .header > .project-name {
    color: #0E3F91;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 13px; /* 130% */
    letter-spacing: 1px;
    text-transform: uppercase;
}
.hub-ts-timesheetTable-td.task .header.red > .task-code { background-color: #E4456E; color: #FFF}
.hub-ts-timesheetTable-td.task .header.red > .project-name { color: #E4456E; }

.hub-ts-timesheetTable-td.task .header.pink > .task-code { background-color: #EA33F4; color: #FFF}
.hub-ts-timesheetTable-td.task .header.pink > .project-name { color: #EA33F4; }

.hub-ts-timesheetTable-td.task .header.green > .task-code { background-color: #54A340; color: #FFF}
.hub-ts-timesheetTable-td.task .header.green > .project-name { color: #54A340; }

.hub-ts-timesheetTable-td.task .header.blue > .task-code { background-color: #6680F7; color: #FFF}
.hub-ts-timesheetTable-td.task .header.blue > .project-name { color: #6680F7; }

.hub-ts-timesheetTable-td.task .header.orange > .task-code { background-color: #EC6337; color: #FFF}
.hub-ts-timesheetTable-td.task .header.orange > .project-name { color: #EC6337; }

.hub-ts-timesheetTable-td.task .header.navy > .task-code { background-color: #4A68D9; color: #FFF}
.hub-ts-timesheetTable-td.task .header.navy > .project-name { color: #4A68D9; }

.hub-ts-timesheetTable-td.task .header.yellow > .task-code { background-color: #F2A54A; color: #FFF}
.hub-ts-timesheetTable-td.task .header.yellow > .project-name { color: #F2A54A; }

.hub-ts-timesheetTable-td.task .header.teal > .task-code { background-color: #5BC4DD; color: #FFF}
.hub-ts-timesheetTable-td.task .header.teal > .project-name { color: #5BC4DD; }

.hub-ts-timesheetTable-td.task .header.gray > .task-code { background-color: #75737F; color: #FFF}
.hub-ts-timesheetTable-td.task .header.gray > .project-name { color: #75737F; }


.hub-ts-timesheetTable-td.task .metrics { 
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    gap:8px;
}

.hub-ts-timesheetTable-td.task .details { 
    margin-top: 12px;
    color: var(--hub500, #1769F1);
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 15.6px; /* 130% */

    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;

}


.hub-ts-timesheetTable-td.task .sub-info {
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.hub-ts-timesheetTable-td.done {
    background-color: #F5F7FA;
    min-width: 80px;
    width: 80px;
    border-right: 1px solid #ddd;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.hub-ts-timesheetTable-td.done input[type='checkbox'] { 
    cursor: pointer;
}


.hub-ts-modalWrapper{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.hub-ts-modal {
    width: 80%;
    height: 60%;
    background: #FFFFFF;
    margin: 100px auto;
    border-radius: 4px;
    box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
}  

.hub-ts-modalHeader {
    background-color: #f5f7fa;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px;
    justify-content: space-between;
}

.hub-ts-modalHeader-title {
    font-weight: 600;
    margin-top: 4px;
}

.hub-ts-modalHeader-title > .icon {
    border-radius: 50%;
    width: 21px;
    height: 21px;
    display: inline-block;
    text-align: center;
    border:1px solid #000000;
    margin-right: 4px;
}

.hub-ts-modalBody-filter {
    background-color: #f5f7fa;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px;
}

.hub-ts-modalBody-body {
    padding: 16px;
    background-color: #f5f7fa;
    height: calc(80vh - 253px);
    overflow: auto;
}
.hub-ts-modalBody-table  th {
    background-color: #fff;
    text-align: left;
    padding: 3px 15px;
    vertical-align: middle;
    font-size: 1.1em;
    border: 1px solid rgba(0,0,0,.15);
    border-top: 0px;
    height: 40px;
    white-space: nowrap;
}

.hub-ts-modalBody-table  td {
    background-color: #fff;
    padding: 3px 15px;
    vertical-align: middle;
    font-size: 17px;
    height: 50px;
    border: 1px solid rgba(0,0,0,.15);
    cursor: pointer;
}

.hub-ts-modalBody-filter .item {
    display: inline-block;   
    border: 1px solid rgba(0,0,0,.15);
    background: #FFF;
    padding:16px;
    padding-right: 4px;
    width: 190px;
}

.hub-ts-modalBody-filter .item input {
    border:0;
    font-family: 'Open Sans';
    font-size: 14px;
    outline: transparent;
    margin-left:4px
}

.hub-ts-modalHeader-buttons {
    display: flex;
}
