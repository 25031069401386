.metric-wrapper {
  width: 100%;
  height: 100%;
  padding: 25px 0px 31px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.hub-do-metricChart {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;
}

.hub-do-metricChart .title {
  color: var(--hub900, #051530);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hub-do-metricChart .value {
  align-items: center;
  color: var(--hub900, #051530);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 83.333% */
  position: relative;
  width: max-content;
}

.hub-do-metricChart .value .percantage-symbol {
  color: var(--hub900, #051530);
  font-family: "Noto Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 200;
  line-height: 50px; /* 166.667% */
  position: absolute;
  left: -30px;
  top: -8px;
}

.hub-do-metricChart .subText {
  color: var(--hub900, #051530);
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
